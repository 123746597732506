import React from 'react';
import Loadable from 'react-loadable'
import LoadingOverlay from 'react-loading-overlay';

import {viewChangeSwitch} from './utils/api';

function Loading() {
  return <div>
            <LoadingOverlay
                      active={true}
                      spinner
                      text='Loading...'
                      >
             </LoadingOverlay>
          </div>;
}

const Downloads = Loadable({
  loader: () => import('./views/Clubs/Downloads'),
  loading: Loading,
});

const Clubs = Loadable({
  loader: () => import('./views/Clubs/Clubs'),
  loading: Loading,
});

const Stats = Loadable({
  loader: () => import('./views/Clubs/Stats'),
  loading: Loading,
});

const ClubTriggers = Loadable({
  loader: () => import('./views/Clubs/ClubTriggers'),
  loading: Loading,
});

const ClubTriggersNew = Loadable({
  loader: () => import('./views/Clubs/ClubTriggers/TriggersNew'),
  loading: Loading,
});

const Providers = Loadable({
  loader: () => import('./views/Clubs/Providers'),
  loading: Loading,
});

const ProvidersNew = Loadable({
  loader: () => import('./views/Clubs/Providers/ProvidersNew'),
  loading: Loading,
});

const Members = Loadable({
  loader: () => import('./views/Clubs/Members'),
  loading: Loading,
});

const MembersNew = Loadable({
  loader: () => import('./views/Clubs/Members/MembersNew'),
  loading: Loading,
});

const Settings = Loadable({
  loader: () => import('./views/Clubs/Settings'),
  loading: Loading,
});

const HelpView = Loadable({
  loader: () => import('./views/Pages/Help/HelpView'),
  loading: Loading,
});

const ContactUs = Loadable({
  loader: () => import('./views/Pages/ContactUs/ContactUs'),
  loading: Loading,
});

const ContactUsConfirmation = Loadable({
  loader: () => import('./views/Pages/ContactUs/ContactUsConfirmation'),
  loading: Loading,
});

const routes = [
  { path: '/', exact: true, name: 'Home', component: null },
  { path: '/register', exact: true, name: 'Register', component: null },
  { path: '/registerconfirmation', name: 'Register Confirmation', component: null },
  { path: '/loggingout', exact: true, name: 'Logout Message', component: null },
  { path: '/downloads', name: 'Downloads', component: Downloads },
  { path: '/organisations', exact: true, name: 'Organisations', component: Clubs },
  { path: '/clubs/clubtriggers', exact: true, name: 'Clubtriggers', component: viewChangeSwitch.newTriggerList ? ClubTriggersNew : ClubTriggers },
  { path: '/dashboard', exact: true, name: 'Dashboard', component: Stats },
  { path: '/providers', name: 'Providers', component: viewChangeSwitch.newProviderList ? ProvidersNew : Providers },
  { path: '/members', name: 'Members', component: viewChangeSwitch.newMemberList ? MembersNew : Members },
  { path: '/settings', name: 'Settings', component: Settings },
  { path: '/help', name: 'Help', component: HelpView },
  { path: '/contactus', name: 'Contact Us', component: ContactUs },
  { path: '/contactusconf', name: 'Contact Us', component: ContactUsConfirmation },
];

export default routes;
