import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { Container, Col, Row } from 'reactstrap';
import { isUserLoggedIn } from "../../utils/api";
class DefaultFooter extends Component {
  addContactUsLink(links) {
    const linkToExists = links.some((link) => link.linkTo === '/contactus');
    if (!linkToExists) {
      return [
        ...links,
        {
          linkTo: '/contactus',
          textToDisplay: 'Contact us',
        },
      ];
    // eslint-disable-next-line no-unreachable
    return links;
    }
  }

  render() {
    const isLoggedIn = isUserLoggedIn();
    const { footerLinks } = this.props;
    const updatedFooterLinks = isLoggedIn ? this.addContactUsLink(footerLinks) : footerLinks;


    return (
      <Container>
        <Row className="justify-content-center">
          {updatedFooterLinks.map((link, index) => {
            return (
              <Col key={`footer-link${index}`} md="6" className="footer-link-wrapper">
                <Link to={link.linkTo} className="footer-link">{link.textToDisplay}</Link>
              </Col>
            );
          })}
        </Row>
      </Container>
    );
  }
}

DefaultFooter.propTypes = {
  footerLinks: PropTypes.arrayOf(PropTypes.shape({
    linkTo: PropTypes.string,
    textToDisplay: PropTypes.string,
  }))
};

DefaultFooter.defaultProps = {
  footerLinks: [
    // {
    //   linkTo: '/privacy',
    //   textToDisplay: 'Privacy'
    // },
    // {
    //   linkTo: '/terms-of-use',
    //   textToDisplay: 'Terms of use'
    // },
    // {
    //   linkTo: '/copyright',
    //   textToDisplay: 'Copyright and disclaimer'
    // },
    // {
    //   linkTo: '/access-info',
    //   textToDisplay: 'Accessing information'
    // },
    {
      linkTo: '/faq',
      textToDisplay: 'Frequently Asked Questions'
    },
    //Removed contact us from here and only added when the user is logged in
    // {
    //   linkTo: '/contactus',
    //   textToDisplay: 'Contact us'
    // }
  ]
};



export default DefaultFooter;
