import React, { Component } from 'react';
import { Container, Row, Nav } from 'reactstrap';
import PropTypes from 'prop-types';
import { AppNavbarBrand, AppSidebarToggler } from '@coreui/react';
import logo from '../../assets/img/brand/logo.png'
import { getProfileInfo, auth0userDetailsKey } from '../../utils/api'
import { NotificationContainer } from 'react-notifications';
import { connect } from "react-redux";

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

class DefaultHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: getProfileInfo(null, auth0userDetailsKey, "nickname"),
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.firstname && nextProps.firstname.length > 0) {
      this.setState({ userName: nextProps.firstname })
    }
  }

  render() {
    return (
      <React.Fragment>
        <Container fluid>
          <Row className="header-row header-justify-mobile">
            <div className="col-md-1 hide-on-desktop width-zero"><AppSidebarToggler className="d-lg-none header-nav-toggle fa fa-bars club-blue" display="md" mobile /></div>
            <div className="col-md-2 width-zero"><AppNavbarBrand
              full={{ src: logo, width: '120px', height: 'auto', alt: 'Service NSW' }}
              className={'header-nav'}
              href={'/dashboard'}
            /></div>
            <div className="col-md-3 hide-on-mobile">
              <div className="header-title">
                Welcome, {this.state.userName}
              </div>
            </div>
            <div className="col-md-auto width-zero">
              <Nav navbar>
                <NotificationContainer />
              </Nav>
            </div>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}


DefaultHeader.propTypes = propTypes;
DefaultHeader.defaultProps = defaultProps;

const updateUserDetailsProps = state => {
  const { updateUserDetails } = state;
  const {
    firstname
  } = updateUserDetails.action || {
    firstname: ''
  };


  return {
    firstname
  };
};

export default connect(updateUserDetailsProps)(DefaultHeader);