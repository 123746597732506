import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './TextInputWithLabel.css';

class TextInputWithLabel extends Component {
  render() {
    if (this.props.readOnly) {
      return (
        <div className="text-input-container">
          <label>{this.props.labelText}</label>
          <input
            type='text'
            readOnly={true}
            value={this.props.value}
            id={this.props.id}
            onChange={this.props.onChange}
            className={this.props.className || 'read-only'}
          />
        </div>
      );
    } else {
      return (
        <div className="text-input-container">
          <label>{this.props.labelText}</label>
          <input
            type='text'
            value={this.props.value}
            id={this.props.id}
            onChange={this.props.onChange}
            className={this.props.className || ''}
          />
        </div>
      );
    }
  }
}

TextInputWithLabel.propTypes = {
  labelText: PropTypes.string,
  value: PropTypes.string,
  readOnly: PropTypes.bool,
  id: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string
};

export default TextInputWithLabel;