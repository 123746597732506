import { ClubManageApis, loadData } from "../utils/api";
export const INVALIDATE_MEMBERSLIST = 'INVALIDATE_MEMBERSLIST'
export const API_ERRORS = 'API_ERRORS'
export const REQUEST_MEMBERSLIST = 'REQUEST_MEMBERSLIST'
export const RECEIVE_MEMBERSLIST = 'RECEIVE_MEMBERSLIST'
export const REQUEST_MEMBERUPDATE = 'REQUEST_MEMBERUPDATE'
export const REQUEST_ADDNEWMEMBER = 'REQUEST_ADDNEWMEMBER'

export const defaultMemberData = {
  updatedrow: 
     {
      "username":"",
      "email": "",
      "firstName":"",
      "lastName":"",
      "password": "",
      "roles":[]
      },
  touched: 
    {
      "email":false,
      "firstName":false,
      "lastName":false,
      "roles":false
    }
};

export const invalidateMemberList = MemberList => ({
  type: INVALIDATE_MEMBERSLIST,
  MemberList
})

export const apiErrors = (MemberList, error) => ({
  type: API_ERRORS,
  MemberList,
  error
})

export const requestMemberList = MemberList => ({
  type: REQUEST_MEMBERSLIST,
  MemberList
})

export const receiveMemberList = (MemberList, members) => ({
  type: RECEIVE_MEMBERSLIST,
  MemberList,
  members,
  receivedAt: Date.now(),
  updatedMemberData: {}
})

export const requestMemberUpdate = (MemberList, isFetching, updatedMemberData, updateComplete = false) => ({
  type: REQUEST_MEMBERUPDATE,
  MemberList,
  isFetching,
  updatedMemberData,
  updateComplete
})

export const requestAddNewMember = (MemberList, isFetching, updatedMemberData, addNewMemberComplete = false) => ({
  type: REQUEST_ADDNEWMEMBER,
  MemberList,
  isFetching,
  updatedMemberData,
  addNewMemberComplete
})

/* fetch Member list */

export const fetchMembersIfNeeded = MemberList => (dispatch, getState) => {
  if (shouldFetchMemberList(getState(), MemberList)) {
    return dispatch(fetchMemberList(MemberList))
  }else{
    dispatch(requestMemberList(MemberList))
    var state = getState();
    dispatch(receiveMemberList(MemberList, state.postsByMemberList.memberlist.members
))
  }
}

const shouldFetchMemberList = (state, MemberList) => {
  const members = state.postsByMemberList.memberlist
  if (!members) {
    return true
  }
  if (members.isFetching) {
    return false
  }
  return members.didInvalidate
}

const fetchMemberList = MemberList => dispatch => {
  dispatch(requestMemberList(MemberList))
  loadData(ClubManageApis.clubsolutionusers, "GET", null).then(result => {
      if (result.status !== 200) {
        if(result.response && result.response.data && result.response.data.message){
          dispatch(apiErrors(MemberList, Error(result.response.data.message)))
        }
      }else if (result.status === 200 && result && result.data && result.data.users) {
        dispatch(receiveMemberList(MemberList, result.data.users))
      }
    });
}

/* Change Member Status*/

export const updateMemberDetails = (MemberList, memberDate) => (dispatch, getState) => {

  dispatch(requestMemberUpdate(MemberList, true, memberDate))

  const data = {
    id: memberDate.id,
    disabled: memberDate.disabled,
    roles: memberDate.roles,
    firstName: memberDate.firstName,
    lastName: memberDate.lastName
  };

  loadData(ClubManageApis.clubsolutionuserdetails, "post", data, true).then(result => {
      if (result.status !== 200) {
        if(result.response && result.response.data && result.response.data.message){
          dispatch(apiErrors(MemberList, Error(result.response.data.message)))
        }
      }else if (result.status === 200 && result) {
        var state = getState();
        const members = state.postsByMemberList.memberlist.members;
        const id = members.findIndex(x => x.id === state.postsByMemberList.memberlist.updatedMemberData.id)
        members[id] = state.postsByMemberList.memberlist.updatedMemberData;
        dispatch(requestMemberUpdate(MemberList, true, members, true))
      }
    });
}

export const addNewMember = (MemberList, newMemberDate) => (dispatch, getState) => {
  const data = {
                  username: newMemberDate.username,
                  email: newMemberDate.email,
                  firstname: newMemberDate.firstName,
                  lastname: newMemberDate.lastName,
                  password: newMemberDate.password,
                  roles:newMemberDate.roles
              }
    dispatch(requestAddNewMember(MemberList, true, newMemberDate))

    loadData(ClubManageApis.clubsolutionuser, "post", data, true).then(result => {
      if (result.status !== 200) {
        if(result.response && result.response.data && result.response.data.message){
          dispatch(apiErrors(MemberList, Error(result.response.data.message)))
        }
      }else if (result.status === 200 && result && result.data && result.data.update && result.data.update === "successful"){
        var state = getState();
        dispatch(requestAddNewMember(MemberList, false, state.postsByMemberList.memberlist.updatedMemberData, true))
      }
    });
}

export const deleteMember = (MemberList, newMemberDate) => (dispatch, getState) => {
  dispatch(requestMemberUpdate(MemberList, true, newMemberDate))

  loadData(ClubManageApis.clubsolutionuser, "delete", newMemberDate, true).then(result => {
    if (result.status !== 200) {
      if(result.response && result.response.data && result.response.data.message){
        dispatch(apiErrors(MemberList, Error(result.response.data.message)))
      }
    } else if (result.status === 200 && result && result.data && result.data.update && result.data.update === "successful"){
      //refresh the memberlist
      dispatch(requestMemberList(MemberList));
      dispatch(receiveMemberList(MemberList, result.data.users))
    }
  });
}

