import React, { Component } from 'react';
import { Container, Col, Row } from 'reactstrap';
import { Link } from "react-router-dom";

import { DefaultLayoutWithoutNavigation } from '../../../containers/index';
import tempImage from '../../../assets/img/brand/snsw-vis-mark.png';
import tempLogo from '../../../assets/img/brand/favicon.png';
import Feature from '../../components/Feature';
import ImageBox from '../../components/ImageBox';

let dummyFeatures = [];

class Home extends Component {

  //TODO: currently, renderFeatures() is populating dummy features, we need to list actual features for this section
  renderFeatures() {
    dummyFeatures = [];
    for (let index = 0; index < 6; index += 1) {
      dummyFeatures.push({
        title: `Feature ${index + 1}`
      });
    }
   
    return (
      <Container>
        {dummyFeatures && 
          <Row className="justify-content-center">
            {dummyFeatures.map((feature) => {
              if (feature && feature.title) {
                return (<Col sm="4">
                  <Feature 
                    title={feature.title}
                    description={feature.description}
                  />
                </Col>);
              } else {
                return undefined;
              }
            })}
          </Row>
        }
      </Container>
    );
  }

  renderLogoImages() {
    return (
      <Container>
        {dummyFeatures && 
          <Row className="justify-content-center">
            {dummyFeatures.map(() => {
              return (<Col sm="4">
                <ImageBox 
                  imageUrl={tempLogo}
                  cssClassName='bottom-image'
                />
              </Col>);
            })}
          </Row>
        }
      </Container>
    );
  }

  render() {

    return (
      <DefaultLayoutWithoutNavigation  showLoginButton  showRequestAccessButton>
        <Container className="custom-font-size home-page-wrapper">

          <Row className="justify-content-center section-wrapper">
            <Col lg="12">
              <Row>
                <Col md="12">
                  <div className="text-center section-title"><span>Partner Entitlement Validation Portal</span></div>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col md="6">
                  <div>
                    <p>
                      At Service NSW, we aim to empower YOU, NSW citizens, by simplifying the way you do business with government and putting you needs at the heart of everything with optimal service for a seamless data access.
                    </p>
                    <p>
                      This portal allows government partners agencies to easily capture NSW citizens digital licences data, with their consent, to simplify authorised entitlement checks and everyday's transactions.
                    </p>
                    <p>
                      Partner agencies can easily receive this information by integrating the Digital Licence Platform with their own system, following consent giving by a licence holder. Licence holders can easily and in a transparent way check who has received access to their information and when and control who their share it with.
                    </p>
                    <p>
                      This digital service is supported by best-in-class security and high-availability platforms to ensure your data is safe.
                    </p>
                  </div>
                  <div className="link-button extra-margin">
                    <Link to="/register" color="primary" className="btn custom-font-size btn-large primary-button-color">Request Access</Link>
                  </div>
                </Col>
                <Col md="6">
                  <img src={tempImage} alt="SNSW" className="content-image"></img>
                </Col>
              </Row>
            </Col>
          </Row>
          </Container>
      </DefaultLayoutWithoutNavigation>
    );
  }
}

export default Home;
