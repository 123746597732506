import React, { Component } from 'react';
import { Container, Card, CardBody, Col, Row, Form } from 'reactstrap';

import { DefaultLayoutWithoutNavigation } from '../../containers/index';

class Privacy extends Component {
  
  render() {
    
    return (
      <DefaultLayoutWithoutNavigation>
        <div>
          <Container >
            <section className="head">
                <div className="container">
                    <h2 className="text-center"><span>Privacy Page</span></h2>
                </div>
            </section>
            <Row className="justify-content-center">
              <Col md="6">
                  <Card className="p-4">
                    <CardBody>
                      <Form>
                        
                      </Form>

                    </CardBody>
                  </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </DefaultLayoutWithoutNavigation>
    );
  }
}

export default Privacy;
