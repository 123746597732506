import * as crypto from "crypto";
import axios from 'axios';
import qs from 'qs';

import history from '../history';
import {
  StorageHelper,
  Config,
  LogoutUser, 
  getProfileInfo
} from "../utils/api";


export default class Auth {

  code;
  accessToken;
  idToken;
  expiresAt;
  refreshToken;
  userProfile;
  tokenRenewalTimeout;

  getAppApiHeader() {
    const headers = {};
    if (this.idToken != null) {
      headers["Authorization"] = "Bearer " + this.idToken;
      headers["requestTimeStamp"] = "2017-05-01T14:00:00.000Z";
      headers["transactionID"] = "1";
      headers["deviceID"] = "5fe1d74e-6d37-412d-835c-d2ee3bf12uat";
      headers["platform"] = "1";
      headers["Content-Type"] = "application/json";
    }
    return headers;

  }


  constructor() {
    this.getAppApiHeader = this.getAppApiHeader.bind(this);
    this.login = this.login.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.getAccessToken = this.getAccessToken.bind(this);
    this.getIdToken = this.getIdToken.bind(this);
  }

  createVerifierChallenge() {
    function base64URLEncode(str) {
      return str
        .toString("base64")
        .replace(/\+/g, "-")
        .replace(/\//g, "_")
        .replace(/=/g, "");
    }

    function sha256(buffer) {
      return crypto
        .createHash("sha256")
        .update(buffer)
        .digest();
    }
    let verifier = base64URLEncode(crypto.randomBytes(32));
    let challenge = base64URLEncode(sha256(verifier));
    return [verifier, challenge];
  }


  buildAuthorizationRequestUrl(nonce, code_challenge) {
    return (
      Config.authorizationEndpoint +
      "?response_type=code" +
      "&client_id=" +
      Config.clientId +
      "&scope=" +
      encodeURI(Config.scopes) +
      "&redirect_uri=" +
      Config.redirectUri +
      "&nonce=" +
      nonce +
      "&code_challenge=" +
      code_challenge +
      "&code_challenge_method=S256" +
      "&audience=partner-portal"
    );
  }

  generateNonce(length) {
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (var i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  parseQueryString(url) {
    var regex = /[#?&]([^=#]+)=([^&#]*)/g,
      params = {},
      match;

    while ((match = regex.exec(url))) {
      params[match[1]] = match[2];
    }

    return params;
  }

  login() {
    var nonce = this.generateNonce(32);
    var verifierchallenge = this.createVerifierChallenge();
    StorageHelper.saveData("code_verifier", verifierchallenge[0]);
    var authorizationUrl = this.buildAuthorizationRequestUrl(
      nonce,
      verifierchallenge[1]
    );
    window.location = authorizationUrl;
  }

  register() {
    var verifierchallenge = this.createVerifierChallenge();
    StorageHelper.saveData("code_verifier", verifierchallenge[0]);
    var authorizationUrl = `${Config.registrationEndpoint}?returnUrl=${Config.homeUri}`;
    window.location = authorizationUrl;
  }
  async handleAuthentication() {

    var callback = this.parseQueryString(window.location.href);

    if (!callback.state && !callback.code && !callback.error) {
      history.replace('/home');
    }

    if (callback.error) {
      history.replace('/unauthorized')
    }

    var urls = JSON.stringify(callback, null, 2);
    StorageHelper.saveData("id", urls);

    try {
      this.code = callback.code;
      const authResult = await this.makeTokenRequest();
      if (authResult && authResult.access_token && authResult.id_token) {

        this.setSession(authResult);

        // navigate to the home route
        history.replace('/');

      }
    } catch (err) {
      history.replace('/home');
      console.log(err);
      alert(`Error: ${err.error}. Check the console for further details.`);
    }

  }

  setSession(authResult) {
    StorageHelper.saveData('isLoggedIn', 'true');
    StorageHelper.saveData('id', JSON.stringify(authResult, null, 2));
    
    this.setAuthTokens(authResult);
  }
  
  setAuthTokens(authResult) {
    // @TODO uncomment this to set the token to expire in 2 min
    // the /oauth/token call fails, to debug why swap the two lines below to have a faster token expiration and a call to /oauth/token
    // this.expiresAt = authResult.expires_in + new Date().getTime() 
    
    this.expiresAt = getProfileInfo(authResult.access_token, "exp");
    this.accessToken = authResult.access_token;
    this.idToken = authResult.id_token;
    this.refreshToken = authResult.refresh_token;
  }

  getSession() {
    let authResult = JSON.parse(StorageHelper.getData('id'));
    if (authResult != null) {
      this.setAuthTokens(authResult);
      this.scheduleRenewal();
    }
  }

  async makeTokenRequest() {
    try {
      let params = {
        grant_type: "authorization_code",
        client_id: Config.clientId,
        redirect_uri: Config.redirectUri,
        code: this.code,
        code_verifier: StorageHelper.getData("code_verifier"),
        audience: "partner-portal ",
      };
      const response = await axios({
        method: "POST",
        headers: {
          "Content-Type" : 'application/x-www-form-urlencoded',
        },
        url: Config.tokenEndpoint,
        data: qs.stringify(params),
      })

      return response.data;
    } catch (error) {
      console.log('Error at makeTokenRequest', error);
    }
  }

  isAuthenticated() {
    return (new Date().getTime() < this.expiresAt && this.accessToken);
  }

  getAccessToken() {
    return this.accessToken;
  }

  getIdToken() {
    return this.idToken;
  }

  scheduleRenewal() {
      this.tokenRenewalTimeout = setTimeout(() => {
        this.renewSession();
      }, this.getTimeoutInMillisecondsForTokenRenewal());
  }
  
  // this value is the difference in milliseconds between the access_token expiration time and the current time
  getTimeoutInMillisecondsForTokenRenewal() {
    const now = parseInt(Date.now() / 1000, 10)
    const timeout = this.expiresAt - now;

    return timeout * 1000;
  }


  async renewSession() {

    try {
      //same page, not reloaded, so make code as null 
      this.code=null;
      const authResult = await this.makeTokenRequest();
      this.setSession(authResult);
    } catch (err) {
      LogoutUser();
      // If this is an error and it needs to be known it should be logged
      // console.log can reveal information about the system
      //console.log(err);
    }
  }

  logout() {
    // Remove tokens and expiry time
    this.accessToken = null;
    this.idToken = null;
    this.expiresAt = 0;

    // Remove user profile
    this.userProfile = null;

    // Clear token renewal
    clearTimeout(this.tokenRenewalTimeout);

    // Remove isLoggedIn flag from localStorage
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('id');

    // navigate to the home route
    history.replace('/home');
  }

}

window.addEventListener('storage', function(event){
  // eslint-disable-next-line eqeqeq
  if (event.key == 'id' && event.newValue == null){
    history.replace('/home');
  }
});

