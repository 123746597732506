import React, {Component} from 'react';
import { DefaultLayoutWithoutNavigation } from '../../../containers/index';
import {isUserLoggedIn} from '../../../utils/api';
import {Container, Col, Row} from 'reactstrap';

class RegisterConfirmation extends Component {
  render() {
    const url = `/club/${isUserLoggedIn() ? 'dashboard' : 'home'}`;
    return (
      <DefaultLayoutWithoutNavigation>
        <Container>
          <section className="head">
            <div className="container text-center pt-5">

              <Row className="justify-content-center">
                <Col md="8">
                  <div className="pt-5">
                    <h2 className="text-center"><span className="pageTitle">Register and your request is sent!</span></h2>
                    <h6 className="pageSubTitle pt-4">Once your account is approved, we will send an email to your registered email address to verify your access and get you started.</h6>
                  </div>
                </Col>
              </Row>
            </div>
            <br/>
            <div id="return-to-dashboard" className="form-group text-center pt-5">
              <a
                href={url}
                id="dashboard-button"
                className="contactUsConfDashboard"
                name="dashboard-button"
              >
                {isUserLoggedIn() ? 'Return to Dashboard' : 'Return to Home'}
              </a>
            </div>
          </section>
        </Container>
      </DefaultLayoutWithoutNavigation>
    );
  }
}

export default RegisterConfirmation;
