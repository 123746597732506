import React from 'react';
import PropTypes from 'prop-types';
import { Container, Col, Row } from 'reactstrap';
import image from '../../assets/img/brand/logo.png';

class ImageBox extends React.Component {
  render() {
    return (
      <Container className="dlp-home-page--bottom-logo-container">
        <Row>
          <Col sm="12">
            <img src={this.props.imageUrl} alt="" className={this.props.cssClassName} />
          </Col>
        </Row>
      </Container>
    );
  }
}

ImageBox.propTypes = {
  imageUrl: PropTypes.string,
  cssClassName: PropTypes.string.isRequired,
};

ImageBox.defaultProps = {
  imageUrl: image,
};

export default ImageBox;

