import {
INVALIDATE_PROVIDERLIST,
API_ERRORS,
REQUEST_PROVIDERLIST,
RECEIVE_PROVIDERLIST,
REQUEST_PROVIDERSTATUSCHANGE
} from "../actions/providers";

const INITIAL_STATE = {
  isFetching: false,
  didInvalidate: false,
  providers: [],
  error: null,
  errorMessage: ""
};

const provider = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case INVALIDATE_PROVIDERLIST:
      return {
        ...state,
        didInvalidate: true
      };
    case API_ERRORS:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        error: action.error
      };
    case REQUEST_PROVIDERLIST:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
       updateComplete: false
      };
    case RECEIVE_PROVIDERLIST:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        providers: action.providers,
        lastUpdated: action.receivedAt,
        updateComplete: false,
        sizePerPage: action.sizePerPage, 
        currentPage: action.currentPage,
        totalDataSize: action.totalDataSize, 

      };
    case REQUEST_PROVIDERSTATUSCHANGE:
      return {
        ...state,
        isFetching: action.isFetching,
        didInvalidate: false,
        updatedProviderData: action.updatedProviderData,
        updateComplete: false,
        error: null
      };
    default:
      return state;
  }
};

export const postsByProviderList = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_PROVIDERLIST:
    case API_ERRORS:
    case REQUEST_PROVIDERLIST:
    case RECEIVE_PROVIDERLIST:
    case REQUEST_PROVIDERSTATUSCHANGE:
      return {
        ...state,
        [action.ProviderList]: provider(state[action.ProviderList], action)
      };
    default:
      return state;
  }
};