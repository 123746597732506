import React from 'react';
import PropTypes from 'prop-types';
import { Container, Col, Row } from 'reactstrap';
import image from '../../assets/img/brand/favicon.png';

class Feature extends React.Component {
  render() {
    return (
      <Container className="feature-container">
        <Row>
          <Col sm="5">
            <img src={this.props.imageUrl} alt={this.props.title} className="feature-image" />
          </Col>
          <Col sm="7">
            <div className="feature-title">{this.props.title.toUpperCase()}</div>
            <div>{this.props.description}</div>
          </Col>
        </Row>
      </Container>
    );
  }
}

Feature.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  imageUrl: PropTypes.string,
};

Feature.defaultProps = {
  description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam ut dolor libero.',
  imageUrl: image,
};

export default Feature;
