import React from "react";
import PropTypes from "prop-types";
import {Row, Col} from 'reactstrap';

import './TextareaWithLabel.css';

export class TextareaWithLabel extends React.Component {
    render() {
        return (
            <div className="text-area-container">
                <Row>
                    <Col md="6">
                        <label id="label-text">{this.props.labelText}</label>
                    </Col>
                    {this.props.charLimit &&
                    <Col md="6" className="text-right-align">
                        <label id="label-char-limit">Character limit {this.props.charLimit}</label>
                    </Col>
                    }
                </Row>
                <Row>
                    <Col md="12">
                    <textarea
                        id={this.props.id}
                        rows={this.props.rows}
                        maxLength={this.props.charLimit}
                        onChange={this.props.validationHandler}
                    />
                    </Col>
                </Row>
            </div>
        );
    }
}

TextareaWithLabel.propTypes = {
    labelText: PropTypes.string,
    id: PropTypes.string,
    charLimit: PropTypes.number,
    rows: PropTypes.number,
    validationHandler: PropTypes.func
};