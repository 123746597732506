import { Provider } from "react-redux";
import { ClubManageApis, loadData } from "../utils/api";
export const INVALIDATE_PROVIDERLIST = 'INVALIDATE_PROVIDERLIST'
export const API_ERRORS = 'API_ERRORS'
export const REQUEST_PROVIDERLIST = 'REQUEST_PROVIDERLIST'
export const RECEIVE_PROVIDERLIST = 'RECEIVE_PROVIDERLIST'
export const REQUEST_PROVIDERSTATUSCHANGE = 'REQUEST_PROVIDERSTATUSCHANGE'

export const invalidateProviderList = ProviderList => ({
  type: INVALIDATE_PROVIDERLIST,
  ProviderList
})

export const apiErrors = (ProviderList, error) => ({
  type: API_ERRORS,
  ProviderList,
  error
})

export const requestProviderList = ProviderList => ({
  type: REQUEST_PROVIDERLIST,
  ProviderList
})

export const receiveProviderList = (ProviderList, providers, sizePerPage, currentPage, totalDataSize ) => ({
  type: RECEIVE_PROVIDERLIST,
  ProviderList,
  providers,
  sizePerPage, 
  currentPage,
  totalDataSize, 
  receivedAt: Date.now(),
  updatedProviderData: {}
})

export const requestProviderStatusChange = (ProviderList, isFetching, updatedProviderData) => ({
  type: REQUEST_PROVIDERSTATUSCHANGE,
  ProviderList,
  isFetching,
  updatedProviderData
})

/* fetch Provider list */
export const fetchProvidersIfNeeded = (ProviderList, sizePerPage, currentPage )=> (dispatch, getState) => {
  return dispatch(fetchProviderList(ProviderList, sizePerPage, currentPage));
}

export const fetchProviderList = (ProviderList, sizePerPage, currentPage ) => dispatch => {
  dispatch(requestProviderList(ProviderList));
  var providerAdminsurl = ClubManageApis.clubsolutionadmins;
  loadData(providerAdminsurl, "GET", null).then(result => {
      if (result.status !== 200) {
        if(result.response && result.response.data && result.response.data.message){
          dispatch(apiErrors(ProviderList, Error(result.response.data.message)));
        }
      }else if (result.status === 200 && result && result.data && result.data.users) {
        dispatch(receiveProviderList(ProviderList, result.data.users, sizePerPage, currentPage, result.data.totalCount));
      }
    });
}

/* Change Provider Status*/

export const shouldChangeProviderStatus = (ProviderList, newProviderDate) => (dispatch, getState) => {
  dispatch(requestProviderStatusChange(ProviderList, true, newProviderDate));
  const data = {
    id: newProviderDate.id,
    disabled: newProviderDate.disabled
  };
  loadData(ClubManageApis.clubsolutionadminstatus, "post", data, true).then(result => {
      if (result.status !== 200) {
        if (result.response && result.response.data && result.response.data.message){
          dispatch(apiErrors(ProviderList, Error(result.response.data.message)));
        }
      } else if (result.status === 200 && result) {
        var state = getState();
        const providers = state.postsByProviderList.providerlist.providers;
        const id = providers.findIndex(x => x.id === state.postsByProviderList.providerlist.updatedProviderData.id);
        providers[id] = state.postsByProviderList.providerlist.updatedProviderData;
        dispatch(receiveProviderList(ProviderList, 
          providers, 
          state.postsByProviderList.providerlist.sizePerPage,
          state.postsByProviderList.providerlist.currentPage, 
          state.postsByProviderList.providerlist.totalDataSize));
      }
    });
};

export const deleteProvider = (ProviderList, newProviderData) => (dispatch, getState) => {
  dispatch(requestProviderList(ProviderList));
  loadData(ClubManageApis.provider, "delete", JSON.stringify(newProviderData), null).then(result => {
    if (result.status !== 200) {
      if (result.response && result.response.data && result.response.data.message){
        dispatch(apiErrors(ProviderList, Error(result.response.data.message)));
      }
    } else {
      let state = getState();
      dispatch(fetchProvidersIfNeeded(ProviderList,
        state.postsByProviderList.providerlist.sizePerPage,
        state.postsByProviderList.providerlist.currentPage));
    }
  });
};