import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import "./polyfill";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { releaseType } from "./utils/api";

import { createStore, applyMiddleware, combineReducers } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";


import { postsByClublist, postsByTriggerlist } from "./reducers";
import { postsByProviderList } from "./reducers/providers";
import { postsByMemberList } from "./reducers/members";
import { updateUserDetails } from "./reducers/updateUserName";

import { createLogger } from "redux-logger";
import "react-notifications/lib/notifications.css";
import ReactGA from "react-ga";

if (releaseType === "prod") {
  ReactGA.initialize("UA-141265147-1");
}

const rootReducer = combineReducers({
  postsByClublist,
  postsByTriggerlist,
  postsByProviderList,
  updateUserDetails,
  postsByMemberList
});

const middleware = [thunk];
if (process.env.NODE_ENV !== "production") {
  middleware.push(createLogger());
}

const store = createStore(rootReducer, applyMiddleware(...middleware));

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
