import React, { Component } from 'react';
import { Router, Route, Switch, Redirect } from "react-router-dom";
import history from './history';

import './App.css';
// Styles
// CoreUI Icons Set
import '@coreui/icons/css/coreui-icons.min.css';
// Import Flag Icons Set
import 'flag-icon-css/css/flag-icon.min.css';
// Import Font Awesome Icons Set
import 'font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
import 'simple-line-icons/css/simple-line-icons.css';
// Import Main styles for this application
import './scss/style.css'

// Containers
import { DefaultLayout, DefaultLayoutWithoutNavigation } from './containers';
import authRequiredRoutes from './routes';

// Pages
import {
  Home,
  Login,
  Callback,
  Register,
  Logout,
  Page404,
  Page500,
  AccessingInformation,
  CopyrightAndDisclaimer,
  Privacy,
  TermsOfUse,
  Faq,
  LogoutMessage,
  Unauthorized
} from './views/Pages';
import ContactUs from './views/Pages/ContactUs/ContactUs';
import ContactUsConfirmation from './views/Pages/ContactUs/ContactUsConfirmation';
import Auth from './auth/Auth';
import {isUserLoggedIn, viewChangeSwitch} from './utils/api';

import ReactGA from 'react-ga';
import RegisterConfirmation from "./views/Pages/Register/RegisterConfirmation";
history.listen(location =>ReactGA.pageview(location.pathname));

const auth = new Auth();

class App extends Component {

  componentWillMount() {
    auth.getSession();
  }

  render() {
    return (
      <Router history={history}> 
        <Switch>
          <Route exact path="/home" name="Home" render={(props)=> <Home auth={auth} {...props} /> }  />
          <Route exact path="/login" name="Login Page" render={(props)=><Login auth={auth} {...props} />}  />
          <Route exact path="/register" name="Registration Page" render={(props)=><Register auth={auth} {...props} />}  />
          <Route exact path="/registerconfirmation" name="Registration Confirmation Page" render={(props)=><RegisterConfirmation auth={auth} {...props} />}  />
          <Route path="/callback" render={(props)=><Callback auth={auth} {...props} />}   />
          <Route path="/logout"  render={(props)=><Logout auth={auth} {...props} />}   />
          <Route exact path="/loggingout" name="Logging Out Page" render={(props)=> <LogoutMessage auth={auth} {...props} /> }  />
          <Route exact path="/Unauthorized" name="Unauthorize Page" render={(props)=> <Unauthorized auth={auth} {...props} /> }  />
          <Route exact path="/404" name="Page 404" render={(props)=><Page404 auth={auth} {...props} />}  />
          <Route exact path="/500" name="Page 500" render={(props)=><Page500 auth={auth} {...props} />}  />
          <Route exact path="/access-info" name="Accessing Information Page" component={AccessingInformation}  />
          {viewChangeSwitch.allowAnonymousContactUs && 
            <Route exact 
              path="/contactus"
              name="Contact Us Page" 
              render={(props)=> {
                if (!isUserLoggedIn()) {
                  return(
                    <DefaultLayoutWithoutNavigation
                      showLoginButton={true}
                      showRequestAccessButton={true}
                    >
                      <ContactUs {...props} />
                    </DefaultLayoutWithoutNavigation>
                  );
                } else {
                  return (
                    <DefaultLayout {...props}>
                      <ContactUs {...props} />
                    </DefaultLayout>
                  );
                }
              }}  
            />
          }
          {viewChangeSwitch.allowAnonymousContactUs && 
            <Route exact 
              path="/contactusconf"
              name="Contact Us Confirmation Page" 
              render={(props)=> {
                if (!isUserLoggedIn()) {
                  return(
                    <DefaultLayoutWithoutNavigation 
                      showLoginButton={true}
                      showRequestAccessButton={true}
                    >
                      <ContactUsConfirmation {...props} />
                    </DefaultLayoutWithoutNavigation>
                  );
                } else {
                  return (
                    <DefaultLayout {...props}>
                      <ContactUsConfirmation {...props} />
                    </DefaultLayout>
                  );
                }
              }}  
            />
          }
          <Route exact path="/copyright" name="Copyright and Disclaimer Page" component={CopyrightAndDisclaimer}  />
          <Route exact path="/privacy" name="Home Page" component={Privacy}  />
          <Route exact path="/terms-of-use" name="Home Page" component={TermsOfUse}  />
          <Route exact path="/faq" name="Frequently Asked Questions Page" component={Faq}  />

          {authRequiredRoutes.map((route, idx) => {
                    return route.component ? (<Route key={route.name} path={route.path} exact={route.exact} name={route.name} render={props => (
                      !isUserLoggedIn() ? (
                        <Redirect to="/home"/>
                      ) : (
                        <DefaultLayout {...props}>
                          <route.component  {...props} />
                        </DefaultLayout>
                      )

                      )} />)
                      : (null);
                  },
                )}

      <Redirect from="/" to="/dashboard" />


        </Switch>
      </Router>
    );
  }
}

export default App;
