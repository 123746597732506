import React, { Component } from 'react';
import { Container, Col, Row } from 'reactstrap';

import { DefaultLayoutWithoutNavigation } from '../../containers/index';

class Faq extends Component {
  
  render() {
    
    return (
      <DefaultLayoutWithoutNavigation  showLoginButton  showRequestAccessButton>
        <div>
          <Container className="custom-font-size">

            <Row className="justify-content-center section-wrapper">
              <Col lg="12">
                <Row>
                  <Col md="12">
                    <div className="text-center section-title"><span>Frequently Asked Questions</span></div>
                  </Col>
                </Row>
                <Row className="question-wrapper">
                  <Col md="12">
                    <span className="bold-font">
                      I am a licence owner. Who can I share my government information with?
                    </span>
                    <br />
                    <ul>
                      We are partnering with NSW clubs to allow us to access clubs services using your Digital Driver Licence at the moment.
                    </ul>
                  </Col>
                </Row>
                <Row className="question-wrapper">
                  <Col md="12">
                    <span className="bold-font">
                      I am a partner relying on licence checks to provide services and benefits to my customer / employees. How can I learn more about the data capture solution?
                    </span>
                    <br />
                    <ul>
                      If your company also performs entitlement checks and requires a certain level of entitlement to provide NSW citizens services or benefits, you call contact us today and learn more about our platform.
                    </ul>
                  </Col>
                </Row>
                <Row className="question-wrapper">
                  <Col md="12">
                    <span className="bold-font">
                      How does it work for our partners relying on licence check and data capture?
                    </span>
                    <br />
                    <ol>
                      <li>Request access to our portal by registering your company as well as yourself as admin.</li>
                      <li>We will get in touch with you to discuss legal aspects of the data capture solution.</li>
                      <li>Once an account has been set up for you, update your system/APIs to ensure you can receive safely digital licences information. Provide your API details in your portal account.</li>
                      <li>Specify the type of information you will need to capture to provide services to customers.</li>
                      <li>Easily manage your "venues" and generate QR code for each of them.</li>
                      <li>Let digital licence holders scan the QR code to easily share their licence details with you.</li>
                    </ol>
                  </Col>
                </Row>
                <Row className="question-wrapper">
                  <Col md="12">
                    <span className="bold-font">
                      How does it work for licence owners needing to share their personal entitlements and licence details?
                    </span>
                    <br />
                    <ol>
                      <li>Download with Service NSW app on your smartphone.</li>
                      <li>Create an account or login to your MyServiceNSW Account.</li>
                      <li>Link your account to your Driver Licence.</li>
                      <li>Generate your Digital Driver Licence (or any other Digital Licence).</li>
                      <li>Use the "share" button to easily share your data with a partner and scanning their QR code.</li>
                      <li>Check and review the list of information the partner needs to capture.</li>
                      <li>Consent or refuse to share your information with them.</li>
                      <li>Later, review who you shared your information with.</li>
                    </ol>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      </DefaultLayoutWithoutNavigation>
    );
  }
}

export default Faq;
