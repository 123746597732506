import React, { Component } from 'react';

import LoadingOverlay from 'react-loading-overlay';

class Callback extends Component {

  async componentDidMount() {
    await this.props.auth.handleAuthentication();
  }

  render() {

    return <LoadingOverlay
    active={true}
    spinner
    text='Loading...'
    background="rgba(256, 256, 256, 0.92)"
    color="#000"
    spinnerSize="140px"
    >
    </LoadingOverlay>

  }
}


export default Callback;
