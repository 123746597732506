import {
INVALIDATE_MEMBERSLIST,
API_ERRORS,
REQUEST_MEMBERSLIST,
RECEIVE_MEMBERSLIST,
REQUEST_MEMBERUPDATE,
REQUEST_ADDNEWMEMBER
} from "../actions/members";

const INITIAL_STATE = {
  isFetching: false,
  didInvalidate: false,
  members: [],
  error: null,
  errorMessage: ""
};

const provider = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case INVALIDATE_MEMBERSLIST:
      return {
        ...state,
        didInvalidate: true,
        error: null,
      };
    case API_ERRORS:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        error: action.error
      };
    case REQUEST_MEMBERSLIST:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
        updateComplete: false,
        addNewMemberComplete: false,
        error: null,
      };
    case RECEIVE_MEMBERSLIST:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        members: action.members,
        lastUpdated: action.receivedAt,
        updateComplete: false,
        addNewMemberComplete: false,
      };
    case REQUEST_MEMBERUPDATE:
      return {
        ...state,
        isFetching: action.isFetching,
        didInvalidate: false,
        updatedMemberData: action.updatedMemberData,
        updateComplete: action.updateComplete,
        error: null,
        addNewMemberComplete: false,
      };
    case REQUEST_ADDNEWMEMBER:
      return {
        ...state,
        isFetching: action.isFetching,
        didInvalidate: false,
        updatedMemberData: action.updatedMemberData,
        addNewMemberComplete: action.addNewMemberComplete,
        error: null
      };
    default:
      return state;
  }
};

export const postsByMemberList = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_MEMBERSLIST:
    case API_ERRORS:
    case REQUEST_MEMBERSLIST:
    case RECEIVE_MEMBERSLIST:
    case REQUEST_MEMBERUPDATE:
    case REQUEST_ADDNEWMEMBER:
      return {
        ...state,
        [action.MemberList]: provider(state[action.MemberList], action)
      };
    default:
      return state;
  }
};