import {
  ClubManageApis,
  loadData
} from "../utils/api";

export const REQUEST_CLUBLIST = 'REQUEST_CLUBLIST'
export const RECEIVE_CLUBLIST = 'RECEIVE_CLUBLIST'
export const REQUEST_CLUBSTATUSCHANGE = 'REQUEST_CLUBSTATUSCHANGE'
export const INVALIDATE_CLUBLIST = 'INVALIDATE_CLUBLIST'
export const API_ERRORS = 'API_ERRORS'
export const REQUEST_CLUBUPDATE = 'REQUEST_CLUBUPDATE'


export const SHOW_CLUBTRIGGERS = 'SHOW_CLUBTRIGGERS'
export const EDIT_CLUBTRIGGERS = 'EDIT_CLUBTRIGGERS'
export const REQUEST_CLUBTRIGGERS = 'REQUEST_CLUBTRIGGERS'
export const RESPONSE_CLUBTRIGGERS = 'RESPONSE_CLUBTRIGGERS'
export const REQUEST_CLUBTRIGGERSTATUSCHANGE = 'REQUEST_CLUBTRIGGERSTATUSCHANGE'
export const REQUEST_CLUBTRIGGERSUPDATE = 'REQUEST_CLUBTRIGGERSUPDATE'
export const API_ERRORS_TRIGGER = 'API_ERRORS_TRIGGER'
export const START_CLUBTRIGGERUPDATE = 'START_CLUBTRIGGERUPDATE'
export const REQUEST_OPERATIONCLAIM = 'REQUEST_OPERATIONCLAIM'

export const defaultClubData = {
  updatedrow: 
    {
      properties: [{
                    "key": "clubinterface",
                    "value": ""
                },
                {
                    "key": "clubinterface.membershipurl",
                    "value": ""
                },
                {
                    "key": "clubinterface.profile",
                    "value": "000000000000000000000000"
                },
                {
                    "key": "planbprocessingmessage",
                    "value": "Please wait while we process your request."
                }],
      name: "",
      addressLine: "",
      city: "",
      state: "",
      postcode: "",
      plan: "",
      membershipUrl: "",
      logoImage: "",
      disabled: false
    },
  touched: 
    {
        name: false,
        addressLine: false,
        city: false,
        state: false,
        postcode: false,
        plan: false,
        logoImage: false,
        "clubinterface.profile": false,
        "clubinterface.membershipurl": false,
        planbprocessingmessage: false,
        clubinterface: false
    }
};

export const defaultTriggerData =  {
  updatedTriggerData: {
    "id": null,
    "triggerTypeName": "qrcode",
    "triggerDeviceId": "",
    "description": "",
    "disabled": false
  },
  touched: {
    "description": ""
  }
};

export const invalidateclubList = clubList => ({
  type: INVALIDATE_CLUBLIST,
  clubList
})

export const apiErrors = (clubList, error) => ({
  type: API_ERRORS,
  clubList,
  error
})

export const requestClublist = clubList => ({
  type: REQUEST_CLUBLIST,
  clubList
})

export const requestClubStatusChange = (clubList, isFetching, updatedClubData, isFullUpdate = false) => ({
  type: REQUEST_CLUBSTATUSCHANGE,
  clubList,
  isFetching,
  updatedClubData,
  isFullUpdate
})

export const requestClubUpdate = (clubList, isFetching, isFullUpdate = false, updateComplete = false) => ({
  type: REQUEST_CLUBUPDATE,
  clubList,
  isFetching,
  isFullUpdate,
  updateComplete
})

export const receiveClublist = (clubList, postsMaster, posts, profiles, totalCount, currentPage, sizePerPage) => ({
  type: RECEIVE_CLUBLIST,
  clubList,
  postsMaster,
  posts,
  profiles,
  totalCount,
  receivedAt: Date.now(),
  updatedClubData: {},
  currentPage, 
  sizePerPage
})

export const showTriggersList =(clubList, updatedClubData) => ({
  type: SHOW_CLUBTRIGGERS,
  clubList,
  updatedClubData
})

export const editTriggersList =(clubList, updatedTriggerData) => ({
  type: EDIT_CLUBTRIGGERS,
  clubList,
  updatedTriggerData
})

export const requestClubTriggers =(clubList, isFetching) => ({
  type: REQUEST_CLUBTRIGGERS,
  clubList,
  isFetching
})

export const responseClubTriggers = (clubList, isFetching, triggerList, updateComplete) => ({
  type: RESPONSE_CLUBTRIGGERS,
  clubList,
  isFetching,
  updateComplete,
  triggerList
})

export const startClubTriggerUpdate = (clubList, isFetching, updatedTriggerData, isFullUpdate = false, updateComplete = false, triggerList) => ({
  type: START_CLUBTRIGGERUPDATE,
  clubList,
  isFetching,
  updatedTriggerData,
  isFullUpdate
})
export const requestClubTriggerStatusChange = (clubList, isFetching, updatedTriggerData, isFullUpdate = false, updateComplete = false, triggerList) => ({
  type: REQUEST_CLUBTRIGGERSTATUSCHANGE,
  clubList,
  isFetching,
  updatedTriggerData,
  isFullUpdate,
  updateComplete,
  triggerList
})

export const requestClubTriggerUpdate = (clubList, isFetching, updatedTriggerData, isFullUpdate = false, updateComplete = false) => ({
  type: REQUEST_CLUBTRIGGERSUPDATE,
  clubList,
  isFetching,
  isFullUpdate,
  updateComplete,
  updatedTriggerData
})

export const apiErrorsTrigger = (clubList, error, endpoint = "") => ({
  type: API_ERRORS_TRIGGER,
  clubList,
  error,
  endpoint
})

export const requestOperationClaims = (clubList, isFetching) => ({
  type: REQUEST_OPERATIONCLAIM,
  clubList,
  isFetching,
});

/* fetch fields to capture (aka operationclaims) */
const defaultOperationClaims = [
  {
    name: "licenseename",
    displayName: "Name",
    value: '5b5a97879e5bb52e44e17f96',
    isSelected: false,
  },
  {
    name: "dob",
    displayName: "Date of birth",
    value: '5b5a97879e5bb52e44e17f97',
    isSelected: false,
  },
  {
    name: "licencenumber",
    displayName: "Licence number",
    value: '5b5a97879e5bb52e44e17f93',
    isSelected: true,
  },
  {
    name: "cardnumber",
    displayName: "Card number",
    value: '5b5a97879e5bb52e44e17f94',
    isSelected: false,
  },
  {
    name: "expirydate",
    displayName: "Expiry date",
    value: '5b5a97879e5bb52e44e17f95',
    isSelected: false,
  },
  {
    name: "address",
    displayName: "Address",
    value: '5b5a97879e5bb52e44e17f98',
    isSelected: false,
  },
  {
    name: "signature",
    displayName: "Signature",
    value: '5b5a97879e5bb52e44e17f99',
    isSelected: false,
  },
  {
    name: "photo",
    displayName: "Photo",
    value: '5b5a97879e5bb52e44e17f9a',
    isSelected: false,
  },
];

export const fetchOperationClaims = () => {
  let dataFieldsToCapture = [];
  return loadData(ClubManageApis.operationclaims, "GET", null)
    .then(result => {
      // if we get operation claims successfully from db, only then update the default list of operation claims
      // otherwise, return the default operation claim list
      if (result.status === 200) {
        if (result.data && result.data.length > 0) {
          result.data.forEach((item) => {
            dataFieldsToCapture.push({
              name: item.Name,
              displayName: item.DisplayName,
              value: item.Value,
              isSelected: false
            });
          });
          return dataFieldsToCapture;
        } else {
          throw Error('No data found for operation claims in db.');
        }
      } else {
        throw Error('Unable to load operation claims form db.');
      }
    })
    .catch(err => {
      console.error(`${err} Returning default data.`);
      return defaultOperationClaims;
    });
}

/* fetch club list */
export const fetchPostsIfNeeded = (clubList, currentPage, sizePerPage, searchterm = "") => (dispatch, getState) => {
  return dispatch(fetchPosts(clubList, currentPage, sizePerPage, searchterm));
}

const fetchPosts = (clubList, currentPage, sizePerPage, searchterm) => dispatch => {
  dispatch(requestClublist(clubList));

  loadData(ClubManageApis.clublist, "GET", null).then(result => {
    if (result.status !== 200) {
      if (result.response && result.response.data && result.response.data.message){
        dispatch(apiErrors(clubList, Error(result.response.data.message)));
      }
    } else if (result.status === 200 && result && result.data && result.data.clubs && result.data.profiles) {
      dispatch(receiveClublist(clubList, result.data.clubs, result.data.clubs, result.data.profiles, result.data.totalCount, currentPage, sizePerPage));
    }
  });
}

/* Change club list Status*/
export const getClubDetails = (clubList, newClubDate) => (dispatch, getState) => {

  dispatch(requestClubStatusChange(clubList, true, newClubDate));

  dispatch(requestClublist(clubList));
  loadData(ClubManageApis.getclubdetail+newClubDate.id, "GET", null).then(result => {
      if (result.status !== 200) {
        if(result.response && result.response.data && result.response.data.message){
          dispatch(apiErrors(clubList, Error(result.response.data.message)));
        }
      }else if (result.status === 200 && result.data.club) {
        dispatch(requestClubStatusChange(clubList, false, result.data.club, true, false));
      }
    });
}

export const shouldChangeClubStatus = (clubList, newClubDate) => (dispatch, getState) => {
  dispatch(requestClubStatusChange(clubList, true, newClubDate));

  const data = {
    id: newClubDate.id,
    disabled: newClubDate.disabled
  };
  loadData(ClubManageApis.clubstatus, "post", data, true).then(result => {
      if (result.status !== 200) {
        if(result.response && result.response.data && result.response.data.message){
          dispatch(apiErrors(clubList, Error(result.response.data.message)));
        }
      }else if (result.status === 200 && result) {
        var state = getState();
        const posts = state.postsByClublist.clublist.items;
        const id = posts.findIndex(x => x.id === state.postsByClublist.clublist.updatedClubData.id);
        posts[id] = state.postsByClublist.clublist.updatedClubData;
        // We should be completing the same dispatch() as called at the start
        dispatch(requestClubUpdate(clubList, true, true, true));
        //dispatch(receiveClublist(clubList, posts, state.postsByClublist.clublist.profiles, state.postsByClublist.clublist.totalCount, state.postsByClublist.clublist.currentPage, state.postsByClublist.clublist.sizePerPage));
      }
    });
}

export const updateClubDetails = (clubList, newClubData) => (dispatch, getState) => {
  dispatch(requestClubUpdate(clubList, true));
  loadData(ClubManageApis.updateclubDetails, "post", JSON.stringify(newClubData), null).then(result => {
      if (result.status !== 200) {
        if(result.response && result.response.data && result.response.data.message){
          dispatch(apiErrors(clubList, Error(result.response.data.message)));
        }
      }else if (result.status === 200 && result.data.club) {
        var state = getState();
        const posts = state.postsByClublist.clublist.items;
        if (state.postsByClublist.clublist.updatedClubData) {
            const id = posts.findIndex(x => x.id === state.postsByClublist.clublist.updatedClubData.id);
            posts[id] = state.postsByClublist.clublist.updatedClubData;
          }
        dispatch(requestClubUpdate(clubList, false, false, true));
        dispatch(receiveClublist(clubList, posts, state.postsByClublist.clublist.profiles, state.postsByClublist.clublist.totalCount,state.postsByClublist.clublist.currentPage, state.postsByClublist.clublist.sizePerPage));
      }
    });
};

export const deleteClubDetails = (clubList, newClubData) => (dispatch, getState) => {
  dispatch(requestClubUpdate(clubList, true));
  loadData(ClubManageApis.updateclubDetails, "delete", JSON.stringify(newClubData), null).then(result => {
    if (result.status !== 200) {
      if (result.response && result.response.data && result.response.data.message){
        dispatch(apiErrors(clubList, Error(result.response.data.message)));
      }
    } else {
      let state = getState();
      const posts = state.postsByClublist.clublist.items;
      if (state.postsByClublist.clublist.updatedClubData) {
        const id = posts.findIndex(x => x.id === state.postsByClublist.clublist.updatedClubData.id);
        posts[id] = state.postsByClublist.clublist.updatedClubData;
      }
      dispatch(requestClubUpdate(clubList, false, false, true));
      dispatch(receiveClublist(clubList, posts, state.postsByClublist.clublist.profiles, state.postsByClublist.clublist.totalCount,state.postsByClublist.clublist.currentPage, state.postsByClublist.clublist.sizePerPage));
    }
  });
};

export const deleteTrigger = (clubList, newTriggerData) => (dispatch, getState) => {
  dispatch(requestClubTriggers(clubList, true));
  loadData(ClubManageApis.trigger, "delete", JSON.stringify(newTriggerData), null).then(result => {
    if (result.status !== 200) {
      if (result.response && result.response.data && result.response.data.message){
        dispatch(apiErrors(clubList, Error(result.response.data.message)));
      }
    } else {
      let state = getState();
      let updatedTriggerList = [];
      state.postsByTriggerlist.clublist.triggerList.forEach((item) => {
        if (item.id !== newTriggerData.id) {
          updatedTriggerList.push(item);
        }
      });
      dispatch(responseClubTriggers(clubList, false, updatedTriggerList, true));
    }
  });
};

export const getTriggersList = (clubList, newClubDate) => (dispatch, getState) => {
  dispatch(requestClubTriggers(clubList, true));

  loadData(ClubManageApis.getclubdetail+newClubDate.id, "GET", null).then(result => {
      if (result.status !== 200) {
        if (result.response && result.response.data && result.response.data.message) {
          dispatch(apiErrorsTrigger(clubList, Error(result.response.data.message, "getTriggersList")));;
        }
      } else if (result.status === 200 && result.data.triggers) {
        dispatch(responseClubTriggers(clubList, false, result.data.triggers));
      }
    });
}

export const shouldChangeTriggerStatus = (clubList, updatedTriggerData) => (dispatch, getState) => {

  dispatch(startClubTriggerUpdate(clubList, true, updatedTriggerData));

  const data = {
    id: updatedTriggerData.id,
    disabled: !updatedTriggerData.disabled
  };

  loadData(ClubManageApis.triggerstatus, "post", data, true).then(result => {
      if (result.status !== 200 ) {
        if (result.response && result.response.data && result.response.data.message){
          dispatch(apiErrorsTrigger(clubList, Error(result.response.data.message)));
        } 
      } else if (result.status === 200 && result) {
        var state = getState();
        const triggerList = state.postsByTriggerlist.clublist.triggerList;
        const id = triggerList.findIndex(x => x.id === state.postsByTriggerlist.clublist.updatedTriggerData.id);
        const triggerData = state.postsByTriggerlist.clublist.updatedTriggerData;
        triggerData.disabled = !triggerData.disabled;
        triggerList[id] = triggerData;
        dispatch(requestClubTriggerStatusChange(clubList, false, triggerData, false, true, triggerList));
      }
    });
}

export const updateTriggerDetails = (clubList, updatedTriggerData, clubDetails) => (dispatch, getState) => {
  dispatch(requestClubTriggerUpdate(clubList, true, updatedTriggerData));

  const data = {
                "clubId":clubDetails.id,
                "profileid": getProperty("clubinterface.profile", clubDetails)?getProperty("clubinterface.profile", clubDetails):"000000000000000000000000",
                "triggers": [updatedTriggerData]
                };
  loadData(ClubManageApis.updateTriggerDetails, "post", data, true).then(result => {
      if (result.status !== 200) {
        if(result.response && result.response.data && result.response.data.message){
          dispatch(apiErrorsTrigger(clubList, Error(result.response.data.message)));
        }
      } else if (result.status === 200 && result) {
        var state = getState();
        const triggerList = state.postsByTriggerlist.clublist.triggerList;
        if (state.postsByTriggerlist.clublist.updatedTriggerData && state.postsByTriggerlist.clublist.updatedTriggerData.id) {
          const id = triggerList.findIndex(x => x.id === state.postsByTriggerlist.clublist.updatedTriggerData.id);
          const triggerData = state.postsByTriggerlist.clublist.updatedTriggerData;
          triggerList[id] = triggerData;
          dispatch(requestClubTriggerStatusChange(clubList, false, triggerData, false, true, triggerList));
        } else {
          dispatch(requestClubTriggerUpdate(clubList, false, updatedTriggerData, false, true));
        }
      }
    });


}

function getProperty(key, clubDetails){
  if(clubDetails.properties) {
    const propertyDetails = clubDetails.properties.find((element) => {
      if(element.key === key){
       return element 
     }
     return null;
    });
    return propertyDetails ? propertyDetails.value : "";  
  }
  return "";
}
