import React, {Component} from 'react';
import {Container} from 'reactstrap';
import "./ContactUs.css"
import {isUserLoggedIn} from '../../../utils/api';

class ContactUsConfirmation extends Component {
    render() {
        const url = `/club/${isUserLoggedIn() ? 'dashboard' : 'home'}`;
        return (
            <Container>
                <section className="head">
                    <div id="contact-us-confirm" className="container text-center cardHeader">
                        <h2 className="text-center"><span className="pageTitle">Contact Us</span></h2>
                        <h6 className="pageSubTitle">Thank you for your message! 
                        </h6>
                        <br/>
                        <br/>
                        <h6 className="pageSubTitleBold500">What happens next?</h6>
                        <h6 className="pageSubTitle">You will be contacted by our team via email soon</h6>
                    </div>
                    <br/>
                    <div id="return-to-dashboard" className="form-group text-center">
                        <a 
                            href={url}
                            id="dashboard-button"
                            className="contactUsConfDashboard"
                            name="dashboard-button"
                        >
                            {isUserLoggedIn() ? 'Return to Dashboard' : 'Return to Home'}
                        </a>
                    </div>
                </section>
            </Container>
        );
    }
}

export default ContactUsConfirmation;
