import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { Navbar, Nav } from 'reactstrap';
import { Link } from "react-router-dom";
import { getProfileInfo } from '../../utils/api';
import logoImage from '../../assets/img/brand/logo.png';

const propTypes = {
  children: PropTypes.node,
  showLoginButton: PropTypes.bool,
  showRequestAccessButton: PropTypes.bool
};

const defaultProps = {
  showLoginButton: false,
  showRequestAccessButton: false
};

// exporting as a non-connected component, this approach is better for unit testing
// default exported component will be a connected component, this can be used for integration testing, NOT for unit testing
export class DefaultHeaderWithoutNavigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: getProfileInfo(null,"permissions")
    };
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.firstname && nextProps.firstname.length > 0){
      this.setState({ userName: nextProps.firstname })
    }
  }

  render() {
    return (
      <div id="header-without-nav-wrapper">
        <Navbar className="row-full-width" expand="md">
          <Link to="/home" className="header-logo-link">
            <img src={logoImage} className="header-logo" alt="Service NSW logo" />
          </Link>
          <Nav className="ml-auto" navbar>
            <div className="link-button">
              {this.props.showLoginButton && 
                <Link id="header-login-button" to="/login" className="btn custom-font-size btn-login-size secondary-button-color btn-login">Log in</Link>
              }
              {this.props.showRequestAccessButton && 
                <Link id="header-register-button" to="/register" className="btn custom-font-size btn-register-size primary-button-color">Request Access</Link>
              }
            </div>
          </Nav>
        </Navbar>
      </div>
    );
  }
}

DefaultHeaderWithoutNavigation.propTypes = propTypes;
DefaultHeaderWithoutNavigation.defaultProps = defaultProps;

const updateUserDetailsProps = state => {
  const { updateUserDetails } = state;
  const {
    firstname
  } = updateUserDetails.action || {
    firstname:''
  };

  return {
    firstname
  };
};

export default connect(updateUserDetailsProps)(DefaultHeaderWithoutNavigation);
