import React, {Component} from 'react';
import {Card, CardBody, Col, Container, Form, Row} from 'reactstrap';
import {getEmail, getFullName} from "../../../utils/userInfoManager";
import TextInputWithLabel from "../../components/forms/TextInputWithLabel";
import "./ContactUs.css"
import {contactUsSubjectsListData} from "./DropDownList.Data";
import Select from 'react-select';
import {TextareaWithLabel} from "../../components/forms/TextareaWithLabel";
import {regexMatcherType, validateInput} from "../../../helpers/text-helper";
import {apiRequest, ClubManageApis, getXIdToken, isUserLoggedIn} from "../../../utils/api";
import {NotificationManager} from "react-notifications";

export const ERROR_INVALID_MESSAGE = 'Message is required and only alphanumeric/common special characters are allowed';
export const ERROR_INVALID_NAME = 'Name is required and only alphabets are allowed';
export const ERROR_INVALID_EMAIL = 'Email is required and only alphanumeric/common special characters are allowed';
export const ERROR_INVALID_SUBJECT = 'Subject category required';

class ContactUs extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: '',
            email: '',
            message: '',
            subject: '',
            showMessageError: false,
            showSubjectError: false,
            messageError: '',
            subjectError: '',
            showNameError: false,
            showEmailError: false,
            isAuthenticated: false
        };

        this.change = this.change.bind(this);
        this.submit = this.submit.bind(this);
        this.validateMessage = this.validateMessage.bind(this);
        this.renderNameTextbox = this.renderNameTextbox.bind(this);
        this.renderEmailTextbox = this.renderEmailTextbox.bind(this);
    }

    componentDidMount() {
        if (isUserLoggedIn()) {
            this.setState({
                isAuthenticated: true,
                name: getFullName(),
                email: getEmail()
            });
        }
    }
    
    change(event) {
        if (event.value) {
            this.setState({subject: event.value, showSubjectError: false});
        }
    }

    validateMessage(event) {
        const elementId = event.target.id;
        const value = event.target.value;
        switch(elementId) {
            case 'contact-name':
                const isNameValid = validateInput(regexMatcherType.personName, value);
                this.setState({
                    name: value,
                    showNameError: !isNameValid
                });
                break;
            case 'contact-email':
                const isEmailValid = validateInput(regexMatcherType.email, value);
                this.setState({
                    email: value,
                    showEmailError: !isEmailValid
                });
                break;
            default:
                const isMessageValid = validateInput(regexMatcherType.textInput, value);
                this.setState({
                    message: value, 
                    showMessageError: !isMessageValid,
                    messageError: isMessageValid ? '' : ERROR_INVALID_MESSAGE
                });
                break;
        }
    }

    submit(event) {
        event.preventDefault();
        if (this.state.subject === "") {
            this.setState({showSubjectError: true, subjectError: ERROR_INVALID_SUBJECT})
        }
        this.setState({
            showMessageError: !validateInput(regexMatcherType.textInput, this.state.message),
            showSubjectError: !this.state.subject,
            showNameError: !validateInput(regexMatcherType.personName, this.state.name),
            showEmailError: !validateInput(regexMatcherType.email, this.state.email),
        });

        let allFieldsFilled = this.state.subject && this.state.message && this.state.name && this.state.email;
        let noErrors = !this.state.showMessageError && !this.state.showSubjectError && !this.state.showNameError && !this.state.showEmailError;
        if (allFieldsFilled && noErrors) {
            const data = {
                "name": this.state.name,
                "email": this.state.email,
                "category": this.state.subject,
                "message": this.state.message
            };
            apiRequest(ClubManageApis.contactus, "POST", data, getXIdToken(), true).then(result => {
                if (result.status !== 200) {
                    if (result.response && result.response.data.errorDetails && result.response.data.message) {
                        NotificationManager.error(result.response.data.message, 'Please try again later..', 1000);
                    }
                } else if (result.status === 200) {
                    this.props.history.push('/contactusconf');
                }
            });
        }
    }

    renderNameTextbox() {
        if (this.state.isAuthenticated) {
            return (
                <TextInputWithLabel
                    labelText="Name"
                    readOnly={true}
                    id="contact-name"
                    value={this.state.name}
                />
            );
        }
        return (
            <TextInputWithLabel
                labelText="Name"
                id="contact-name"
                value={this.state.name}
                onChange={this.validateMessage}
            />
        );
    }

    renderEmailTextbox() {
        if (this.state.isAuthenticated) {
            return (
                <TextInputWithLabel
                    labelText="Email address"
                    readOnly={true}
                    id="contact-email"
                    value={this.state.email}
                />
            );
        }
        return (
            <TextInputWithLabel
                labelText="Email address"
                id="contact-email"
                value={this.state.email}
                onChange={this.validateMessage}
            />
        );
    }

    render() {
        return (
            <Container>
                <section className="head">
                    <div className="container text-center cardHeader">
                        <h2 className="text-center"><span className="pageTitle">Contact us</span></h2>
                        <h6 className="pageSubTitle">Please leave us your feedback</h6>
                    </div>
                </section>
                <Row className="justify-content-center">
                    <Col md="8">
                        <Card className="cardStyle">
                            <CardBody>
                                <Form>
                                    <Row className="justify-content-center">
                                        <Col md="12">
                                            {this.renderNameTextbox()}
                                        </Col>
                                    </Row>
                                    {this.state.showNameError &&
                                    <Row>
                                        <Col md="12">
                                            <span id="name-error"
                                                  className="invalid-label">{ERROR_INVALID_NAME}</span>
                                        </Col>
                                    </Row>
                                    }
                                    <Row className="justify-content-center">
                                        <Col md="12">
                                            {this.renderEmailTextbox()}
                                        </Col>
                                    </Row>
                                    {this.state.showEmailError &&
                                    <Row>
                                        <Col md="12">
                                            <span id="email-error"
                                                  className="invalid-label">{ERROR_INVALID_EMAIL}</span>
                                        </Col>
                                    </Row>
                                    }
                                    <Row className="justify-content-center">
                                        <Col md="12">
                                            <div>
                                                <label id="subjects-list-label">Subject category</label><br/>
                                                <label id="subjects-list-sub-label">Please select a category</label>
                                                <Select
                                                    className="contact-us-reason"
                                                    id="subjects-list"
                                                    onChange={this.change}
                                                    {...contactUsSubjectsListData()}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    {this.state.showSubjectError &&
                                    <Row>
                                        <Col md="12">
                                            <span id="subject-error"
                                                  className="invalid-label">{this.state.subjectError}</span>
                                        </Col>
                                    </Row>
                                    }
                                    <Row className="justify-content-center">
                                        <Col md="12">
                                            <TextareaWithLabel
                                                labelText="Message"
                                                id="message"
                                                charLimit={600}
                                                rows={10}
                                                validationHandler={this.validateMessage}
                                            />
                                        </Col>
                                    </Row>
                                    {this.state.showMessageError &&
                                    <Row>
                                        <Col md="12">
                                            <span id="message-error"
                                                  className="invalid-label">{ERROR_INVALID_MESSAGE}</span>
                                        </Col>
                                    </Row>
                                    }
                                    <Row className="justify-content-center">
                                        <Col md="12">
                                            <div id="send-contactus" className="form-group">
                                                <input id="send-button" type="submit"
                                                       className="contact-us-submit-button"
                                                       name="request-access-button" value="Send" onClick={this.submit}/>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default ContactUs;
