import {getProfileInfo, providerRoles, systemRoles, userRoles, userRolesNames, auth0userDetailsKey} from './api';

export const getFullName = () => {
  let firstName = getProfileInfo(null, auth0userDetailsKey, "firstname");
  let lastName = getProfileInfo(null, auth0userDetailsKey, "lastname");
  return `${firstName} ${lastName}`;
};

export const getEmail = () => {
  return getProfileInfo(null, auth0userDetailsKey, 'email');
};

export const getCurrentUserId = () => {
  return getProfileInfo(null, 'sub').replace('auth0|', '');
};

export const getRolesList = () => {

  return getProfileInfo(null, 'permissions').includes(providerRoles[0])
    ? providerRoles
    : systemRoles;
};

export const getRolesListAsOptions = (role) => {
  return providerRoles.includes(role)
    ? [{value : providerRoles[0], label : userRolesNames[0]}, {value : providerRoles[1], label : userRolesNames[1]}]
    : [{value : systemRoles[0], label : userRolesNames[2]}, {value : systemRoles[1], label : userRolesNames[3]}];
};

export const getRoleName = (role) => {
  return userRolesNames[userRoles.indexOf(role)];
};