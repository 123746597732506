import React, { Component } from 'react';
import LoadingOverlay from 'react-loading-overlay';

class Login extends Component {
  login = () => {
    this.props.auth.login();
  }

  componentWillMount() {
    this.login();
  }

  render() {
    return <LoadingOverlay
            active={true}
            spinner
            text='Loading , please wait...'
            background="rgba(256, 256, 256, 0.92)"
            color="#000"
            spinnerSize="140px"
            ></LoadingOverlay>
  }
}

export default Login;
