import React, { Component } from 'react';
import { Container, Col, Row, Card, CardBody, FormFeedback } from 'reactstrap';
import { DefaultLayoutWithoutNavigation } from '../../../containers/index';
import { Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { apiRequest, ClubManageApis } from "../../../utils/api";
import { regexMatcherType } from "../../../helpers/text-helper";
import { registerData, getRegisterListItems } from "../../../apis/login-apis";
import { NotificationContainer, NotificationManager } from 'react-notifications';

const redirectToRegisterConfirmation = () => {
  window.location = '/club/registerconfirmation';
}

const restartRegisterSession = () => {
  window.location = '/club/register';
}

export default class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // This field has been added to prevent users from continuing to resubmit the form
      submitAttempts: 0,
      industries: [],
      subIndustries: [],
      countries: [],
      states: [],
      filteredSubIndustries: [],
    };
  }

  getSubIndustry = industry => {
    // Simulate async call
    return new Promise((resolve) => {
      let filteredSubIndustries = this.state.subIndustries.filter(subIndustry => subIndustry.industryId === industry);
      // Add "Please select" option
      filteredSubIndustries = [{ text: "Please select", value: "", industryId: "" }, ...filteredSubIndustries];
      resolve(filteredSubIndustries);
    });
  };

  async componentDidMount() {
    try {
      const response = await getRegisterListItems();
      const { industries, subIndustries, countries, states } = response.data;
      this.setState({ industries, subIndustries, countries, states });
    } catch (error) {
      console.error("Failed to fetch register list items:", error);
    }
  }


      render() {
        return (
          <DefaultLayoutWithoutNavigation showBreadcrumb={true}>

            <NotificationContainer />
            <Container className="custom-font-size home-page-wrapper">
              <section className="head">
                <div className="container mb-4">

                  <h1 className="title text-center">Register and Request Access</h1>
                  <div className="intro text-center">
                    Register your organisation and your account to request access to
                    the portal
                  </div>
                </div>
              </section>

              <Formik
                onSubmit={async (values, {setSubmitting}) => {
                  const data = registerData(values);
                  //const delay = ms => new Promise(res => setTimeout(res, ms));

                  //Please note this function was updated. It was previously calling apiRequest function in
                  // src/utils/api.js which created another async request, but this function below is an Async request (?)
                  // In that api.js file there are Headers, but nothing breaks without it. The nested async calls
                  //  were causing back-end AppError in the microservices errors to be forwarded to the user.
                  //  It's a problem because the user should never know the rejection reason like email address already registered.
                  //    If the user has a registration issue, they should contact us!
                  await axios({ method: 'POST', url: ClubManageApis.signup, data: data}).then(async result => {
                    if (result.status === 200) {
                      // Check the status inside the returned data object
                      if( result.data &&
                          result.data.status &&
                          result.data.status === 400)
                      {
                        NotificationManager.error('Please try again later...', 'Error');
                        this.setState({ submitAttempts: this.state.submitAttempts + 1 });
                        setSubmitting(false);
                      } else{
                        return redirectToRegisterConfirmation();
                      } 
                    } else {
                      // It might be good to have a more descriptive error title like 'Error - Network'
                      // If anything goes wrong users can report it and it will be clear the error message
                      //  is ending up here but not clear what the error message is.
                       NotificationManager.error('Please try again later...', 'Error - Network');
                      this.setState({ submitAttempts: this.state.submitAttempts + 1 });
                      setSubmitting(false);
                    }
                  }).catch(error => {
                    // Same error as above for more descriptive error title but using a '!' to make it different
                    // These types of errors should not occur and should be urgently investigated if reported
                    // The try-catch block from microservices should always catch and return errors caught in "result.status !== 200"
                    NotificationManager.error('Please try again later...', 'Error - Network!');
                    this.setState({ submitAttempts: this.state.submitAttempts + 1 });
                    setSubmitting(false);
                  }) 
                }}
                validationSchema={Yup.object().shape({
                  organizationName: Yup.string().required("Organisation name is required").matches(regexMatcherType.businessName, "Enter a valid organization name, only alphanumeric/common special characters are allowed"),
                  abn: Yup.string().required("Organisation ABN/ACN is required").matches(regexMatcherType.businessABN, "Enter a valid organization abn, only numeric characters are allowed"),
                  industry: Yup.string().required("Please select an organisation industry"),
                  subindustry: Yup.string().required("Please select a sub-industry"),

                  streetName: Yup.string().required("Street name is required").matches(regexMatcherType.address, "Enter a valid address, only alphanumeric/common special characters are allowed"),
                  city: Yup.string().required("Suburb or City name is required").matches(regexMatcherType.city, "Enter a valid city name, only alphabets are allowed"),
                  postcode: Yup.string().required("Postcode is required").matches(regexMatcherType.postCode, "Enter a valid postcode, only numeric characters are allowed"),
                  state: Yup.string().required("Please select a state"),
                  country: Yup.string().required("Please select a country"),

                  firstName: Yup.string().required("First name is required").matches(regexMatcherType.personName, "Enter a valid first name, only alphabetic/common special characters are allowed"),
                  lastName: Yup.string().required("Last name is required").matches(regexMatcherType.personName, "Enter a valid last name, only alphabetic/common special characters are allowed"),
                  email: Yup.string().email().required("Email is required").matches(regexMatcherType.email, "Email is required and only alphanumeric/common special characters are allowed"),
                  // Yup needs the .min and .max or else the .max will not be enforced
                  password: Yup.string()
                              .min(10, "Password should be at least 10 characters")
                              .max(20, "Password should be at most 20 characters")
                              .required("Password is required").matches(regexMatcherType.password, "Password should be 10-20 characters and only alphanumeric and common special characters (listed below) are allowed"),
                  passwordConfirm: Yup.string().required("Please re-enter the above password for confirmation").test('passwords-match', 'Passwords must match', function (value) {
                    return this.parent.password === value
                  }),
                })}
              >
                {(props) => {
                  const {
                    values,
                    touched,
                    errors,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                  } = props;
                  return (
                    <section>
                      <form onSubmit={handleSubmit}>
                        {/*Organisation*/}
                        <Row className="justify-content-center">
                          <Col md="12">
                            <Card className="p-4">
                              <CardBody>
                                <div>
                                  <div id="organisation-info-wrapper">
                                    <div className="row mb-2">
                                      <div className="col-md-12">
                                        <b>Organisation</b>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-md-12 form-group">
                                        <label htmlFor="organizationName" className="form-label">
                                          Name
                                        </label>
                                        <input
                                          id="organizationName"
                                          type="text"
                                          value={values.organizationName || ''}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          className={
                                            errors.organizationName && touched.organizationName
                                              ? "form-control input-border is-invalid"
                                              : "form-control input-border"
                                          }
                                          autoComplete="off"
                                        />
                                        {errors.organizationName && touched.organizationName ? <FormFeedback>{errors.organizationName}</FormFeedback> : null}
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-md-12 form-group">
                                        <label htmlFor="abn" className="form-label">
                                          ABN/ACN
                                        </label>
                                        <input
                                          id="abn"
                                          type="text"
                                          value={values.abn}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          className={
                                            errors.abn && touched.abn
                                              ? "form-control input-border is-invalid"
                                              : "form-control input-border"
                                          }
                                          autoComplete="off"
                                        />
                                        {errors.abn && touched.abn ? <FormFeedback>{errors.abn}</FormFeedback> : null}
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-md-12 form-group">
                                        <label htmlFor="industry" className="form-label">
                                          Industry
                                        </label>
                                        <select
                                          id="industry"
                                          value={values.industry}
                                          onChange={
                                            async e => {
                                              const { value } = e.target;
                                              const subindustries = await this.getSubIndustry(value);
                                              setFieldValue("industry", value);
                                              setFieldValue("subindustry", '');
                                              this.setState({ filteredSubIndustries: subindustries });
                                            }
                                          }
                                          onBlur={handleBlur}
                                          style={{ display: "block" }}
                                          className={
                                            errors.industry && touched.industry
                                              ? "form-control input-border is-invalid"
                                              : "form-control input-border"
                                          }
                                          autoComplete="off"
                                        >
                                          {this.state.industries.map(industry => (
                                            <option key={industry.value} value={industry.value}>
                                              {industry.text}
                                            </option>
                                          ))}
                                        </select>
                                        {errors.industry && touched.industry ? <FormFeedback>{errors.industry}</FormFeedback> : null}
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-md-12 form-group">
                                        <label htmlFor="subindustry" className="form-label">
                                          Sub-industry
                                        </label>

                                        <select
                                          id="subindustry"
                                          name="subindustry"
                                          value={values.subindustry}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          style={{ display: "block" }}
                                          className={
                                            errors.subindustry && touched.subindustry
                                              ? "form-control input-border is-invalid"
                                              : "form-control input-border"
                                          }

                                          autoComplete="off"
                                        >
                                          {this.state.filteredSubIndustries &&
                                            this.state.filteredSubIndustries.map(subIndustry => (
                                              <option key={subIndustry.value} value={subIndustry.value} industryid={subIndustry.industryId}>
                                                {subIndustry.text}
                                              </option>
                                            ))}

                                        </select>
                                        {errors.subindustry && touched.subindustry ? <FormFeedback>{errors.subindustry}</FormFeedback> : null}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>

                        {/*Organisation Address*/}
                        <Row className="justify-content-center">
                          <Col md="12">
                            <Card className="p-4">
                              <CardBody>
                                <div>
                                  <div id="organisation-info-wrapper">
                                    <div className="row mb-2">
                                      <div className="col-md-12">
                                        <b>Organisation Address</b>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-md-12 form-group">
                                        <label form="streetName" className="form-label">
                                          Street
                                        </label>
                                        <input
                                          id="streetName"
                                          type="text"
                                          value={values.streetName}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          className={
                                            errors.streetName && touched.streetName
                                              ? "form-control input-border is-invalid"
                                              : "form-control input-border"
                                          }
                                          autoComplete="street-address"
                                        />
                                        {errors.streetName && touched.streetName ? <FormFeedback>{errors.streetName}</FormFeedback> : null}
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-md-12 form-group">
                                        <label htmlFor="city" className="form-label">
                                          Suburb/City
                                        </label>
                                        <input
                                          id="city"
                                          type="text"
                                          value={values.city}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          className={
                                            errors.city && touched.city
                                              ? "form-control input-border is-invalid"
                                              : "form-control input-border"
                                          }
                                          autoComplete="address-level2"
                                        />
                                        {errors.city && touched.city ? <FormFeedback>{errors.city}</FormFeedback> : null}
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-md-4 form-group">
                                        <label htmlFor="postcode" className="form-label">
                                          Post code
                                        </label>
                                        <input
                                          id="postcode"
                                          type="text"
                                          value={values.postcode}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          className={
                                            errors.postcode && touched.postcode
                                              ? "form-control input-border is-invalid"
                                              : "form-control input-border"
                                          }
                                          autoComplete="postal-code"
                                        />
                                        {errors.postcode && touched.postcode ? <FormFeedback>{errors.postcode}</FormFeedback> : null}
                                      </div>
                                      <div className="col-md-4 form-group">
                                        <label htmlFor="state" className="form-label">
                                          State
                                        </label>

                                        <select
                                          id="state"
                                          value={values.state}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          style={{ display: "block" }}
                                          className="form-control input-border"
                                          autoComplete="off"
                                        >
                                          {this.state.states.map(state => (<option key={state.value} value={state.value}>{state.text}</option>))}
                                        </select>
                                        {errors.state && touched.state ? <FormFeedback>{errors.state}</FormFeedback> : null}
                                      </div>
                                      <div className="col-md-4 form-group">
                                        <label htmlFor="country" className="form-label">
                                          Country
                                        </label>

                                        <select
                                          id="country"
                                          value={values.country}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          style={{ display: "block" }}
                                          className="form-control input-border"
                                          autoComplete="off"
                                        >
                                          {this.state.countries.map(country => (
                                            <option key={country.value} value={country.value}>
                                              {country.text}
                                            </option>
                                          ))}
                                        </select>
                                        {errors.country && touched.country ? <FormFeedback>{errors.country}</FormFeedback> : null}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>

                        {/*Provider Admin User*/}
                        <Row className="justify-content-center">
                          <Col md="12">
                            <Card className="p-4">
                              <CardBody>
                                <div>
                                  <div id="organisation-info-wrapper">
                                    <div className="row mb-2">
                                      <div className="col-md-12">
                                        <b>Provider admin user</b>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-md-6 form-group">
                                        <label htmlFor="firstName" className="form-label">
                                          First Name
                                        </label>
                                        <input
                                          id="firstName"
                                          type="text"
                                          value={values.firstName}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          className={
                                            errors.firstName && touched.firstName
                                              ? "form-control input-border is-invalid"
                                              : "form-control input-border"
                                          }
                                          autoComplete="given-name"
                                        />
                                        {errors.firstName && touched.firstName ? <FormFeedback>{errors.firstName}</FormFeedback> : null}
                                      </div>
                                      <div className="col-md-6 form-group">
                                        <label htmlFor="lastName" className="form-label">
                                          Last Name
                                        </label>
                                        <input
                                          id="lastName"
                                          type="text"
                                          value={values.lastName}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          className={
                                            errors.lastName && touched.lastName
                                              ? "form-control input-border is-invalid"
                                              : "form-control input-border"
                                          }
                                          autoComplete="family-name"
                                        />
                                        {errors.lastName && touched.lastName ? <FormFeedback>{errors.lastName}</FormFeedback> : null}
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-md-12 form-group">
                                        <label htmlFor="email" className="form-label">
                                          Email address
                                        </label>
                                        <input
                                          id="email"
                                          type="text"
                                          value={values.email}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          className={
                                            errors.email && touched.email
                                              ? "form-control input-border is-invalid"
                                              : "form-control input-border"
                                          }
                                          autoComplete="email"
                                        />
                                        {errors.email && touched.email ? <FormFeedback>{errors.email}</FormFeedback> : null}
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-md-12 form-group">
                                        <label htmlFor="password" className="form-label">
                                          Password
                                        </label>
                                        <input
                                          id="password"
                                          type="password"
                                          value={values.password}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          className={
                                            errors.password && touched.password
                                              ? "form-control input-border is-invalid"
                                              : "form-control input-border"
                                          }
                                          autoComplete="new-password"
                                        />
                                        {errors.password && touched.password ? <FormFeedback>{errors.password}</FormFeedback> : null}
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-md-12 form-group">
                                        <small className="password-complexity-list">
                                          <ul>
                                            <li>Password must be at least 10 characters and at max 20 characters long.</li>
                                            <li>Password must contain at least 1 UPPERCASE character [A-Z].</li>
                                            <li>Password must contain at least 1 lowercase character [a-z].</li>
                                            <li>Password must contain at least 1 number [0-9].</li>
                                            <li>Password must contain at least 1 symbol [!, @, #, $, %, ^, *, (, ), -, &amp;, _, ?].
                                            </li>
                                          </ul>
                                        </small>
                                      </div>

                                      <div className="col-md-12 form-group">
                                        <label htmlFor="passwordConfirm" className="form-label">
                                          Repeat password
                                        </label>
                                        <input
                                          id="passwordConfirm"
                                          type="password"
                                          value={values.passwordConfirm}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          className={
                                            errors.passwordConfirm && touched.passwordConfirm
                                              ? "form-control input-border is-invalid"
                                              : "form-control input-border"
                                          }
                                          autoComplete="new-password"
                                        />
                                        {errors.passwordConfirm && touched.passwordConfirm ? <FormFeedback>{errors.passwordConfirm}</FormFeedback> : null}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row pt-3">
                                    <div id="register-button-wrapper" className="col-md-12 form-group">
                                      <button id="request-access-button" type="submit" disabled={isSubmitting || this.state.submitAttempts >= 5} className="btn btn-primary p-2 btn-block" name="request-access-button">
                                        <b>Submit</b>
                                        {this.state.submitAttempts >= 5 && <p style={{ textAlign: 'center', fontWeight: 'italics' }}> 
                                          <br /> You have reached the maximum number of Submit attempts. 
                                          <br /> Please refresh the page or contact us for further assistance.</p>
                                        }
                                      </button>
                                      {this.state.submitAttempts >= 1 && <p style={{ textAlign: 'center', fontWeight: 'bold' }}> 
                                        <br /> If you continue to experience issues, please contact us.</p>
                                      }
                                    </div>
                                  </div>

                                </div>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      </form>
                    </section>
                  );
                }}
              </Formik>

            </Container>
          </DefaultLayoutWithoutNavigation>
        )
      }
    }
