import React from 'react';
import PropType from 'prop-types';
import { Container } from 'reactstrap';
import {
  AppBreadcrumb,
  AppFooter,
  AppHeader
} from '@coreui/react';

import routes from '../../routes';
import DefaultFooter from './DefaultFooter';
import DefaultHeaderWithoutNavigation from './DefaultHeaderWithoutNavigation';

class DefaultLayoutWithoutNavigation extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      reload: false
    };
  }

  render() {
    const { children } = this.props;

    return (
      <div className="app">
        <AppHeader fixed className="header-navbar">
          <DefaultHeaderWithoutNavigation showLoginButton={this.props.showLoginButton} showRequestAccessButton={this.props.showRequestAccessButton} />
        </AppHeader>
        <div className="app-body">
          <main className="main">
            {this.props.showBreadcrumb && 
              <div className="breadcrumb-wrapper row-full-width">
                <AppBreadcrumb appRoutes={routes}/>
              </div>}
            <Container fluid className="container-padding">
              {children}
            </Container>
          </main>
        </div>
        <AppFooter className="footer-wrapper">
          <DefaultFooter />
        </AppFooter>
      </div>
    );
  }
}

DefaultLayoutWithoutNavigation.propTypes = {
  showBreadcrumb: PropType.bool,
  showLoginButton: PropType.bool,
  showRequestAccessButton: PropType.bool
};

DefaultLayoutWithoutNavigation.defaultProps = {
  showBreadcrumb: false,
  showLoginButton: false,
  showRequestAccessButton: false
};

export default DefaultLayoutWithoutNavigation;
