import React, { Component } from "react";
import { LogoutUser } from "../../../utils/api";
import LoadingOverlay from "react-loading-overlay";

class Logout extends Component {
  componentDidMount() {
    LogoutUser();
  }
  render() {
    return <LoadingOverlay active={true} spinner text="please wait..."
            background="rgba(256, 256, 256, 0.92)"
            color="#000"
            spinnerSize="140px"/>;
  }
}

export default Logout;
