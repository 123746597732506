import React, { Component } from 'react';
import { Container } from 'reactstrap';

import {
  AppBreadcrumb,
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarNav,
} from '@coreui/react';
// sidebar nav config
import { getNavItemsFor } from '../../_nav';

import routes from '../../routes';
import DefaultFooter from './DefaultFooter';
import DefaultHeader from './DefaultHeader';
import { getProfileInfo } from "../../utils/api";

class DefaultLayout extends Component {

   constructor(props) {
    super(props);
    this.handleEvent = this.filterNavigation.bind(this);
    this.state = {
      userRole: getProfileInfo(null,"permissions"),
      navigation: this.filterNavigation(),
      reload: false
    };
  }

  componentDidMount() {
    this.setState({navigation: this.filterNavigation()})
  }

   setMenu() {
    this.setState({navigation: this.filterNavigation()})
    // return this.filterNavigation()
  }

  filterNavigation(){
    if(this.state){
      this.setState({reload: !this.state.reload})
    }
    
    const jsonOut = Object.create(null)
    if( getProfileInfo(null,"permissions") !== null){
      var navigation = getNavItemsFor(getProfileInfo(null,"permissions"))
      jsonOut.items = navigation.items
    }
    return jsonOut
  }

  render() {
    const { children } = this.props;
    return (
      <div className="app">
        <AppHeader fixed>
          <DefaultHeader />
        </AppHeader>
        <div className="app-body app-body-padding">
          <AppSidebar fixed display="lg">
            <AppSidebarHeader />
            <AppSidebarForm />
            <AppSidebarNav navConfig={this.state.navigation} />
            <AppSidebarFooter />
          </AppSidebar>
          <main className="main">
            <AppBreadcrumb appRoutes={routes}/>
            <Container fluid>
              {children}
            </Container>
          </main>
        </div>
        <AppFooter className="footer-wrapper">
          <DefaultFooter />
        </AppFooter>
      </div>
    );
  }
}

export default DefaultLayout;
