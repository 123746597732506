import React, { Component } from 'react';
import { Container, Col, Row } from 'reactstrap';
import { DefaultLayoutWithoutNavigation } from '../../../containers/index';
import { Config, isUserLoggedIn } from "../../../utils/api";

class LogoutMessage extends Component {

    componentDidMount() {
        // Check if the user is logged in and the loggingout url is directly accessed then redirect to the logout page
        if (isUserLoggedIn()) {
            // redirect to logout page,
            const { history } = this.props; 
            history.replace("/logout");
        }
    }

    render() {

        return (
            <DefaultLayoutWithoutNavigation showLoginButton showRequestAccessButton>
                <div>
                    <Container className="custom-font-size">
                        <Row className="justify-content-center">
                            <Col lg="12">
                                <Row>
                                    <Col md="12">
                                        <div className="text-center section-title">
                                            <span>You have been successfully logged out.</span>
                                            <h3>Click <a href={Config.homeUri}>here</a> to return to the home page.</h3>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </DefaultLayoutWithoutNavigation>
        );
    }
}

export default LogoutMessage;
