import React, { Component } from 'react';
import { Container, Col, Row } from 'reactstrap';
import { DefaultLayoutWithoutNavigation } from '../../../containers/index';

class Unauthorized extends Component {
    
    render() {
        return (
            <DefaultLayoutWithoutNavigation showLoginButton showRequestAccessButton>
                <div>
                    <Container className="custom-font-size">
                        <Row className="justify-content-center">
                            <Col lg="12">
                                <Row>
                                    <Col md="12">
                                        <div className="text-center section-title">
                                            <span>This account has been blocked/deleted. Please contact system admin.</span>
                                            
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </DefaultLayoutWithoutNavigation>
        );
    }
}

export default Unauthorized;
