import {
  INVALIDATE_CLUBLIST,
  REQUEST_CLUBLIST,
  RECEIVE_CLUBLIST,
  REQUEST_CLUBSTATUSCHANGE,
  API_ERRORS,
  REQUEST_CLUBUPDATE,
  RESPONSE_CLUBTRIGGERS,
  SHOW_CLUBTRIGGERS,
  EDIT_CLUBTRIGGERS,
  REQUEST_CLUBTRIGGERS,
  START_CLUBTRIGGERUPDATE,
  REQUEST_CLUBTRIGGERSTATUSCHANGE,
  REQUEST_CLUBTRIGGERSUPDATE,
  API_ERRORS_TRIGGER
} from "../actions";

const INITIAL_STATE = {
  isFetching: false,
  didInvalidate: false,
  items: [],
  profiles: [],
  totalCount: 0,
  error: null,
  errorMessage: ""
};

const posts = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case INVALIDATE_CLUBLIST:
      return {
        ...state,
        didInvalidate: true,
        isFullUpdate: false,
        showTriggerList: false
      };
    case RECEIVE_CLUBLIST:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        itemsMaster: action.postsMaster,
        items: action.posts,
        profiles: action.profiles,
        totalCount: action.totalCount,
        lastUpdated: action.receivedAt,
        isFullUpdate: false,
        updateComplete: false,
        showTriggerList: false,
        currentPage: action.currentPage, 
        sizePerPage: action.sizePerPage
          };
    case REQUEST_CLUBLIST:
      return {
        ...state,
        error: null,
        isFetching: true,
        didInvalidate: false,
        isFullUpdate: false,
       updateComplete: false,
       showTriggerList: false,
      };
    case REQUEST_CLUBSTATUSCHANGE:
      return {
        ...state,
        isFetching: action.isFetching,
        didInvalidate: false,
        updatedClubData: action.updatedClubData,
        isFullUpdate: action.isFullUpdate,
        updateComplete: false,
        showTriggerList: false,
        error: null
      };
    case REQUEST_CLUBUPDATE:
      return {
        ...state,
        isFetching: action.isFetching,
        updateComplete: action.updateComplete,
        isFullUpdate: action.isFullUpdate,
        showTriggerList: false,
        error: null
      };
    case SHOW_CLUBTRIGGERS:
      return {
        ...state,
        updatedClubData: action.updatedClubData,
        isFullUpdate: false,
        showTriggerList: true,
        error: null
      };
    case EDIT_CLUBTRIGGERS:
      return {
        ...state,
        updatedTriggerData: action.updatedTriggerData,
        isFullUpdate: true,
        updateComplete: false,
        error: null
      };
    case REQUEST_CLUBTRIGGERS:
      return {
        ...state,
        isFetching: action.isFetching,
        error: null
      };
    case RESPONSE_CLUBTRIGGERS:
      return {
        ...state,
        isFetching: action.isFetching,
        triggerList: action.triggerList,
        updatedTriggerData: null,
        error: null
      };
    case API_ERRORS:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        error: action.error
      };
    case START_CLUBTRIGGERUPDATE:
      return {
        ...state,
        isFetching: action.isFetching,
        updatedTriggerData: action.updatedTriggerData,
        isFullUpdate: action.isFullUpdate,
        updateComplete: false,
        error: null
      };
    case REQUEST_CLUBTRIGGERSTATUSCHANGE:
      return {
        ...state,
        isFetching: action.isFetching,
        updatedTriggerData: action.updatedTriggerData,
        isFullUpdate: action.isFullUpdate,
        updateComplete: action.updateComplete,
        error: null
      };
      case REQUEST_CLUBTRIGGERSUPDATE:
      return {
        ...state,
        isFetching: action.isFetching,
        updateComplete: action.updateComplete,
        isFullUpdate: action.isFullUpdate,
        updatedTriggerData: action.updatedTriggerData,
        showTriggerList: false,
        error: null
      };
      case API_ERRORS_TRIGGER:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        error: action.error,
        endpoint: action.endpoint
      };
    default:
      return state;
  }
};

export const postsByClublist = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_CLUBLIST:
    case RECEIVE_CLUBLIST:
    case REQUEST_CLUBLIST:
    case REQUEST_CLUBSTATUSCHANGE:
    case API_ERRORS:
    case REQUEST_CLUBUPDATE:
    case SHOW_CLUBTRIGGERS:
      return {
        ...state,
        [action.clubList]: posts(state[action.clubList], action)
      };
    default:
      return state;
  }
};

export const postsByTriggerlist = (state = {}, action) => {
  switch (action.type) {
    case API_ERRORS_TRIGGER:
    case EDIT_CLUBTRIGGERS:
    case REQUEST_CLUBTRIGGERSUPDATE:
    case REQUEST_CLUBTRIGGERSTATUSCHANGE:
    case START_CLUBTRIGGERUPDATE:
    case REQUEST_CLUBTRIGGERS:
    case RESPONSE_CLUBTRIGGERS:
      return {
        ...state,
        [action.clubList]: posts(state[action.clubList], action)
      };
    default:
      return state;
  }
};

