import {ClubManageApis, loadData} from "../utils/api";

export function registerData(data) {
    return {
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        password: data.password,
        confirmPassword: data.passwordConfirm,
        organizationName: data.organizationName,
        abn: data.abn,
        industryId: data.industry,
        subIndustryId: data.subindustry,
        // unit?
        suburb: data.city,
        street: data.streetName,
        postCode: data.postcode,
        state: data.state,
        country: data.country,
    };
}


export function registerDataClubProviderAdmin(data) {
    return {
        userName: data.email,
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        password: data.password,
        organization: {
            organizationId: '',
            organizationName: data.organizationName,
            abn: data.abn,
            addressLine: data.streetName,
            city: data.city,
            state: data.state,
            postcode: data.postcode,
            country: data.country,
        }
    };
}

export function signup(registerData) {
    return loadData(ClubManageApis.signup, "post", registerData, true);
}

export function getRegisterListItems() {
    let url = ClubManageApis.registerlistitmes; 
    return loadData(url, "get", null);
}