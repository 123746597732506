import {userRoles} from "./utils/api";

let items = {
  items: [
    {
      name: 'Dashboard',
      url: '/dashboard',
      icon: 'fa fa-dashboard',
      isAllowed: userRoles,
    },
    // {
    //   title: true,
    //   name: 'Allow/Block',
    //   wrapper: {     
    //     element: '', 
    //     attributes: {} 
    //   },
    //   isAllowed: [userRoles[2], userRoles[3]]
    // },
    {
      name: 'Providers',
      attributes : {id: 'providers'},
      url: '/providers',
      icon: 'icon-drop',
      isAllowed: [userRoles[2], userRoles[3]]
    },
    {
      name: 'Organisations',
      attributes : {id: 'organisations'},
      url: '/organisations',
      icon: 'fa fa-list-alt',
      wrapper: {
        element: '',
        attributes: { className: "my-class", id: "my-id"}  
      },
      isAllowed: [userRoles[0],userRoles[1]]
    },
    {
      divider: true,
      isAllowed: userRoles
    },
    {
      title: true,
      name: 'Account',
      wrapper: {
        element: '',
        attributes: {isAllowed: true},
      },
      isAllowed: userRoles
    },
    {
      name: 'Members',
      attributes : {id: 'members'},
      url: '/members',
      icon: 'fa fa-group',
      class: "sidebar-content-offset",
      isAllowed: [userRoles[0]]
    },
    {
      name: 'User Access',
      attributes : {id: 'userAccess'},
      url: '/members',
      icon: 'fa fa-group',
      class: "sidebar-content-offset",
      isAllowed: [userRoles[2]]
    },
    {
      name: 'Settings',
      attributes : {id: 'settings'},
      url: '/settings',
      icon: 'fa fa-wrench',
      class: "sidebar-content-offset",
      isAllowed: userRoles
    },
    {
      name: 'Help',
      attributes : {id: 'help'},
      url: '/help',
      icon: 'fa fa-question',
      class: "sidebar-content-offset",
      isAllowed: userRoles,
    },
    {
      name: 'Contact us',
      attributes: {id: 'contactUs'},
      url: '/contactus', // TODO 
      icon: 'fa fa-file-text-o',
      class: "sidebar-content-offset",
      isAllowed: userRoles
    },
    {
      name: 'Log out',
      attributes : {id: 'logout'},
      url: '/logout',
      icon: 'fa fa-sign-out',
      class: "sidebar-content-offset",
      isAllowed: userRoles
    }
  ]
};

export function getNavItemsFor(roles){
   const jsonOut = Object.create(null);
  jsonOut.items = items.items.filter((item) => {
      let valid = false;
      for (let i = 0; i < roles.length; i++) {
        if(item.isAllowed.includes(roles[i])){
          valid = true;
          break
        }
      }
      return valid;
    });
    return jsonOut;
}

